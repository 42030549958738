import { Button } from "react-bootstrap"
import add from '../../img/add-black.png'
import '../../Css/Punchlist.css';

interface IAddPunchlistButton {
    handleClick: () => void
}

const AddPunchlistButton: React.FC<IAddPunchlistButton> = (props) => {

    const onHandleClick=()=>{
        props.handleClick()
    }

    return (
        <div className="" style={{ right: '5', bottom: '10' }}>
            <Button className="addButton" style={{backgroundColor:'#f3cc22'}} variant="warning" onClick={()=>onHandleClick()}>
                <img src={add} style={{width:'20px'}} alt="" />
            </Button>
        </div>
    )
}

export default AddPunchlistButton