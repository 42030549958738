import '../../Css/ItrList.css';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ActionButtonIcon from '../../Components/GridSystem/ActionButtonIcon';
import PropertyItem from '../../Components/GridSystem/PropertyItem';
import { formatTime } from '../../Hook/Common/TimeAndDate';
import { RootState, rootStore } from '../../Redux/Store/rootStore';
import { ChangeStatusItrModel, RejectItrModel } from '../../Redux/Action/ItrList/ItrListEditAction';
import left from '../../img/left-chevron.png'
import eye from '../../img/eye.png'
import add from '../../img/add.png'
import { DataModalConfirm, stateConfirm } from '../../Model/CommonModel';
import { DarkTheme } from '../../App';
import { setNotification } from 'Redux/Action/NotificationAction';
import { NotifyType } from 'Redux/Reducer/NotificationReducer';
import CreatePunchlist from 'Screen/Punchlist/CreatePunchlist';
import PunchlistsListGrid from 'Screen/Punchlist/PunchlistsListGrid';
import { PunchlistModel, GET_PL_DETAIL, PunchlistModelDetail, PunchlistModelQuery } from 'Model/PunchlistList';
import PunchlistView from 'Screen/Punchlist/PunchlistView';
import ModalConfirm from 'Components/Various/ModalConfirm';
import { useResourceTableCrud } from 'Hook/Grid/useResourceTableCrud';
import { GetAddResultToGrid, GetPunchlistModel } from 'Redux/Action/PunchlistList/PunchlistListGridAction';


interface Props {
  action: {
    backAction(changed: boolean): any
    setConfirm(data: DataModalConfirm): any
    addPlAction(id: number,isClosedSelected?:boolean): any
  }
  isOnline: boolean
  buttonHidden?: boolean
}

const ItrsListDetail: React.FC<Props> = (props) => {

  const isEdited = localStorage.getItem("isEdited")
  const projectCode = localStorage.getItem('projectCode')


  const initialState: PunchlistModelQuery = {
    projectCode: projectCode !== null ? [projectCode as string] : [],
    fullText: '',
    disciplineCode: [],
    idSubsystem: [],
    statusPL: [],
    sortBy: "",
    isSortAscending: false,
    page: 1,
    pageSize: 15,
    principalId: undefined,
    orphan: false,
    deleted: false,
    lastModified: undefined,
    lastModifiedBy: [],
  }

  const DetailData = (state: RootState) => state.itrListDetailReducer.dtoEdit
  const data = useSelector(DetailData);

  const GridData = (state: RootState) => state.punchlistListGridReducer.gridResult;
  const GridDto = useSelector(GridData);

  const LayoutData = (state: RootState) => state.layoutReducer;
  const layout = useSelector(LayoutData);

  const [changed, setChanged] = useState<boolean>(false)
  const [punchlistItem, setPunchlistItem] = useState(data?.punchList)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isDetailOpened, setIsDetailOpened] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm)

  useResourceTableCrud(initialState, GetPunchlistModel, layout.isMobile ?? true, GetAddResultToGrid, GridDto?.items);



  const openDetail = async (item: PunchlistModel) => {
    rootStore.dispatch({ type: GET_PL_DETAIL, payload: { dtoEdit: item, resultDto: null } as PunchlistModelDetail });
    setIsDetailOpened(true)
  }


  const closeDetail = (changed: boolean) => {
    setIsDetailOpened(false)
  }

  const handleAddPlItem = (id: number,isClosedSelected?:boolean) => {
    props.action.addPlAction(id,isClosedSelected)
  }
  
  useEffect(()=>{
    setPunchlistItem(data?.punchList)
    localStorage.setItem("isEdited","false")
  },[isEdited])

  

  

  const darkTheme = useContext(DarkTheme)

  const confirmChangeStatus = (id: number, status: string) => {
    switch (status) {
      case "New":
        props.action.setConfirm({
          title: "ITR approval",
          buttonText: "Confirm",
          message: "Do you want to change status to this item?",
          isOpen: true,
          actions: {
            cancel: () => { props.action.setConfirm(stateConfirm) },
            confirm: async () => {
              rootStore.dispatch(setNotification({ message: "Error", notifyType: NotifyType.warning }));
            }
          },
        })
        return;
      case "Verified":
        props.action.setConfirm({
          title: "ITR approval",
          buttonText: "Approve",
          secondaryButtonText: "Reject",
          hideCancel: true,
          message: "Select an action on this ITR",
          isOpen: true,
          actions: {
            cancel: () => { props.action.setConfirm(stateConfirm) },
            confirm: async () => {
              await ChangeStatusItrModel(id).then(x => {
                props.action.setConfirm(stateConfirm)
                setChanged(true)
              });
            },
            secondaryConfirm: async () => {
              await RejectItrModel(id).then(x => {
                props.action.setConfirm(stateConfirm)
                setChanged(true)
              });

            },
          },
        })
        return;
      case "Rejected":
        props.action.setConfirm({
          title: "ITR approval",
          buttonText: "Confirm",
          message: "Do you want to change status to this item?",
          isOpen: true,
          actions: {
            cancel: () => { props.action.setConfirm(stateConfirm) },
            confirm: async () => {
              await ChangeStatusItrModel(id).then(x => {
                props.action.setConfirm(stateConfirm)
                setChanged(true)
              });
            }
          },
        })
        return;

      default:
        props.action.setConfirm({
          title: "ITR approval",
          buttonText: "Verify",
          secondaryButtonText: "Reject",
          hideCancel: true,
          message: "Select an action on this ITR",
          isOpen: true,
          actions: {
            cancel: () => { props.action.setConfirm(stateConfirm) },
            confirm: async () => {
              await ChangeStatusItrModel(id).then(x => {
                props.action.setConfirm(stateConfirm)
                setChanged(true)
              });
            },
            secondaryConfirm: async () => {
              await RejectItrModel(id).then(x => {
                props.action.setConfirm(stateConfirm)
                setChanged(true)
              });

            },
          },
        })
        return;
    }
  }


  return (
    <>
      <ModalConfirm data={confirm} />
      {
        isCreateModalOpen ?
          <CreatePunchlist isAssociated={true} isOnline={props.isOnline} action={{ backAction: () => setIsCreateModalOpen(false), addPlAction: handleAddPlItem }} />
          :
          isDetailOpened ?
            <PunchlistView isOnline={props.isOnline} action={{ backAction: closeDetail, setConfirm }} />
            :
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-start col-12 px-1">
                <ActionButtonIcon buttonClassName="icon-sm" imageClassName={darkTheme ? "invert" : ""} action={() => props.action.backAction(changed)} icon={left} />
                <h2 className="mb-0">ITR specifics</h2>
              </div>
              <div className="col-12 pt-2">
                <div className="d-flex col-12 px-0 justify-content-between align-items-center pb-2">
                  <h4 className="mb-0">Details</h4>
                  {props.buttonHidden !== true && data?.statusTxt !== "New" && data?.statusTxt !== "Rejected" ?
                    <ActionButtonIcon buttonClassName="icon-md" imageClassName={darkTheme ? "brightness" : ""} disabled={!props.isOnline} action={() => data?.id && confirmChangeStatus(data?.id, data.statusTxt)} icon={eye} />
                    : null}
                </div>
                <div className="itr-item row mx-0 mb-3">
                  <div className="col-12 row mx-0 py-0 py-lg-2">
                    <PropertyItem label={"ITR code"} property={"ITR code"} index={0} value={data?.itrCode} />
                    <PropertyItem label={"Inspection item"} property={"Inspection item"} index={0} value={data?.itemCode} />
                    <PropertyItem label={"Discipline"} property={"Discipline"} index={0} value={data?.discipline} />
                  </div>
                  <div className="col-12 row mx-0 py-0 py-lg-2">
                    <PropertyItem label={"Itr Type"} property={"Itr Type"} index={0} value={data?.itrType} />
                    <PropertyItem label={"System"} property={"System"} index={0} value={data?.systemCode} />
                    <PropertyItem valueClassName={""} label={"Sub System"} property={"Sub System"} index={0} value={data?.subSystemCode} />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex col-12 px-0 justify-content-between align-items-center pb-2">
                  <h4 className="">Status</h4>
                </div>
                <div className="itr-item row mx-0 mb-3">
                  <div className="col-12 row mx-0 py-0 py-lg-2">
                    <PropertyItem valueClassName={`status ${data?.statusTxt}`} label={"Current status"} property={"Current status"} index={0} value={data?.statusTxt} />
                    <PropertyItem valueClassName={""} labelClassName={""} label={"Date"} property={"Date"} index={0} value={formatTime(data?.date)} />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex col-12 px-0 justify-content-between align-items-center pb-2">
                  <h4 className="">Punch list items</h4>
                  <ActionButtonIcon buttonClassName="icon-md" imageClassName={darkTheme ? "brightness" : ""} disabled={!props.isOnline} action={() => setIsCreateModalOpen(true)} icon={add} />
                </div>
                {
                  punchlistItem?.length === 0 ?
                    <div className="itr-item row mx-0 mb-3">
                      <div className="col-12 row mx-0 py-0 py-lg-2">
                        <p>
                          No punchlist items associated to this ITR
                        </p>
                      </div>
                    </div>
                    :
                    <PunchlistsListGrid isAssociated={true} action={{ openDetail, setConfirm }} data={punchlistItem} renderGrid={GridDto?.gridRender?.render} />
                  // punchlistItem?.map((item, index) => {
                  //   return (
                  //     <Fragment key={item.idUniquePL}>
                  //       <h5 className='font-weight-bold'>Punchlist item {index + 1}</h5>
                  //       <PunchlistDetail plData={item} isOnline={props.isOnline} action={props.action} />
                  //     </Fragment>
                  //   )
                  // })
                }
              </div>
            </div>
      }
    </>
  );
}
export default ItrsListDetail



