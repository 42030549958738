import { HIDE_ERROR, SET_ERROR, ErrorType, NotifyType } from '../Reducer/NotificationReducer'


interface MessageTypeComponent {
    message: string[] | null | string | undefined,
    notifyType: NotifyType,
}


export function setNotification(message: MessageTypeComponent) {
    const obj: { type: string, payload: ErrorType } = {
        type: SET_ERROR,
        payload: { error: message.message ?? "", isOpen: true, notifyType: message.notifyType },
    }
    return obj;
}

export function hideError() {
    return {
        type: HIDE_ERROR
    }
}