import '../../Css/NavMenu.css';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

interface Props {
    label: string
    path: string
    icon: string
    disabled: boolean
}

const ButtonLinkIconTextVert: React.FC<Props> = (props) => {

    const location = useLocation();

    return (
        <Link className={`navIcon-container ${location.pathname === props.path ? "active" : ""} ${props.disabled ? "disabled" : ""}`} to={{ pathname: props.path }}>
            <div className="d-flex flex-column justify-content-center align-items-center px-2 py-2">
                <img alt={props.label} src={props.icon} height="30"></img>
                <label className="m-0 pt-2 text-nowrap">{props.label}</label>
            </div>
        </Link>
    );
}

export default ButtonLinkIconTextVert
