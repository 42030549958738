import React from "react";
import '../../../Css/Filters.css'
import '../../../Css/Index.css'
import Select from 'react-select'
import { FilterValueDto } from "../../../Model/CommonModel";
import { stringIsNullOrEmpty } from '../../../Hook/Common/Utility';

interface Props {
    action: {
        onChange(prop: string, e: any): any,
    }
    data: {
        property: string
        options: FilterValueDto[] | undefined
        selected: (number | string)[] | undefined
    }
    settings: {
        label?: string
        isDisabled?: boolean
        placeHolder?: string
        optionClassName?: string
    }
}

const FiltersSelect: React.FC<Props> = (props) => {
    const options = props.data.options;
    const selected = props.data.selected;

    return (
        <div className="col-12 col-md-6 col-lg-4 d-flex">
            <div className="form-group w-100">
                <label className="labelForm w-100">
                    <span className="text-uppercase text-white">{props.settings.label}</span>
                    <div className="d-flex">
                        <div className="w-100">
                            <Select
                                options={options?.filter(x => !stringIsNullOrEmpty(x.value) && !stringIsNullOrEmpty(x.text)).map(x => { return { key: x.value, value: x.text, code: x.code, dto: x.dto } })}
                                value={options?.map(x => { return { key: x.value, value: x.text, code: x.code, dto: x.dto } })?.filter(x => selected?.includes(x.key))}
                                onChange={(e) => props.action.onChange(props.data.property, e)}
                                isDisabled={props.settings.isDisabled}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key?.toString() ?? ""}
                                placeholder={props.settings.placeHolder}
                                formatOptionLabel={function (data) {
                                    return <span className={`optionText ${props.settings.optionClassName}`} dangerouslySetInnerHTML={{ __html: data.value }} />;
                                }}
                            />
                        </div>
                    </div>
                </label>
            </div>
        </div>
    )
}
export default FiltersSelect