import React from 'react';
import '../../Css/NavMenu.css';
import ButtonLinkIconTextVert from '../Various/ButtonLinkIconTextVert';
import list from '../../img/itrList.png'
import punchList from '../../img/punchList.png'
import actions from '../../img/actions.png'
import archive from '../../img/archive.png'
import profile from '../../img/profile.png'

const NavFooterMobile: React.FC = (props) => {

  return (
    <div className="footerNavMobile-container primary-bg d-flex align-items-center justify-content-around pr-3">
      <ButtonLinkIconTextVert disabled={false} label="ITRs list" path="/" icon={list}></ButtonLinkIconTextVert>
      <ButtonLinkIconTextVert disabled={false} label="Punchlist" path="/punchlist" icon={punchList}></ButtonLinkIconTextVert>
      <ButtonLinkIconTextVert disabled={true} label="Actions" path="/a" icon={actions}></ButtonLinkIconTextVert>
      <ButtonLinkIconTextVert disabled={false} label="Archive" path="/archive" icon={archive}></ButtonLinkIconTextVert>
      <ButtonLinkIconTextVert disabled={false} label="Profile" path="/profile" icon={profile}></ButtonLinkIconTextVert>
    </div>
  );
}

export default NavFooterMobile
