import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_ITR_DETAIL, ItrModelDetail, ApprovedModel, ITR_LOCAL, ItrModel } from '../../../Model/ItrList';
import { ItrListApi } from "../../../Business/ItrListBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import localforage from 'localforage';

export const ChangeStatusItrModel = async (id: number, file?: Blob) => {
    setLoader("ADD", "ChangeStatusItrModel");
    const model = { id: id, file: file } as ApprovedModel
    const api = new ItrListApi();
    const result = await ApiCallWithErrorHandling(() => api.ItrItemChangeStatus(model));
    if (result && !result.warning) {
        rootStore.dispatch({ type: GET_ITR_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ItrModelDetail });

        const list = await localforage.getItem(ITR_LOCAL) as ItrModel[]
        const listFiltered = list?.filter(x => x.id !== id)

        const arr = [] as number[]
        listFiltered?.map(x => {
            arr.push(x.id)
        })
        rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
        localforage.setItem(ITR_LOCAL, listFiltered)
    }

    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    setLoader("REMOVE", "ChangeStatusItrModel");
    return
}

export const ChangeStatusItrRejected = async (id: number, file?: Blob) => {
    setLoader("ADD", "ChangeStatusItrRejected");
    const model = { id: id, file: file } as ApprovedModel
    const api = new ItrListApi();
    const result = await ApiCallWithErrorHandling(() => api.ItrItemChangeStatusRejected(model));
    if (result && !result.warning) {
        rootStore.dispatch({ type: GET_ITR_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ItrModelDetail });

        const list = await localforage.getItem(ITR_LOCAL) as ItrModel[]
        const listFiltered = list?.filter(x => x.id !== id)

        const arr = [] as number[]
        listFiltered?.map(x => {
            arr.push(x.id)
        })
        rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
        localforage.setItem(ITR_LOCAL, listFiltered)
    }

    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    setLoader("REMOVE", "ChangeStatusItrRejected");
    return
}

export const RejectItrModel = async (id: number) => {
    setLoader("ADD", "RejectItrModel");
    const api = new ItrListApi();
    const result = await ApiCallWithErrorHandling(() => api.ItrItemReject(id));
    if (result && !result.warning) {
        rootStore.dispatch({ type: GET_ITR_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ItrModelDetail });
    }
    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    setLoader("REMOVE", "RejectItrModel");
    return
}


