import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_PL_GRID, PunchlistModelGrid, PunchlistModelQuery, PunchlistModel, PL_GRIDRENDER, ADD_PL_GRID } from '../../../Model/PunchlistList';
import { FilterValueDto } from '../../../Model/CommonModel';
import { PunchlistListApi, PunchlistListApiOffline } from "../../../Business/PunchlistListBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StorePunchlistAPList, StorePunchlistDiscList, StorePunchlistFilters, StorePunchlistInspItemList } from './PunchlistStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';
import { setNotification } from '../NotificationAction';
import { NotifyType } from 'Redux/Reducer/NotificationReducer';

export const GetPunchlistModel = async (tableQuery: PunchlistModelQuery) => {
    setLoader("ADD", "GetPunchlistModel");
    const api = navigator.onLine ? new PunchlistListApi() : new PunchlistListApiOffline();
    const response = await ApiCallWithErrorHandling(() => api.PunchlistListGetGrid(tableQuery));
    if (response?.status===404) {
        rootStore.dispatch(setNotification({ message: "No Punchlist item found", notifyType: NotifyType.warning }));
    }else if(response?.status!== 200){
        rootStore.dispatch(setNotification({ message: "Error retrieving Punchlist list", notifyType: NotifyType.error }));
    }
    response?.gridRender && StoreGridRender(response.gridRender, PL_GRIDRENDER);
    rootStore.dispatch({ type: GET_PL_GRID, payload: { gridResult: response, filter: null } as PunchlistModelGrid });
}

export const GetAddResultToGrid = async (tableQuery: PunchlistModelQuery, dataToConcat: PunchlistModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");

    const api = new PunchlistListApi();
    const data = await ApiCallWithErrorHandling(() => api.PunchlistListGetGrid(tableQuery));

    if (data && !arrayIsNullOrEmpty(data.items) && data.items !== undefined) {
        const arr = dataToConcat?.concat(data.items)
        // data.totalItems = data.totalItems;
        data.items = arr;
    }

    rootStore.dispatch({ type: ADD_PL_GRID, payload: { gridResult: data, filter: null } as PunchlistModelGrid });
    return data?.items
}

export async function GetFilterColumnPunchlistModel(columName: string, columValue: string, queryFilter?: PunchlistModelQuery) {
    
    let result: FilterValueDto[] | undefined;
    const api = navigator.onLine ? new PunchlistListApi() : new PunchlistListApiOffline();
    // setLoader("ADD", "GetFilterColumnPunchlistModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.PunchlistListGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.PunchlistListGetFilters(columName, columValue));
    }

    result && StorePunchlistFilters(result, columName);
    return result
}

export async function GetDiscListModel() {
    setLoader("ADD", "GetDiscListModel");

    const api = navigator.onLine ? new PunchlistListApi() : new PunchlistListApiOffline();
    // setLoader("ADD", "GetFilterColumnPunchlistModel");
    const result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.PunchlistGetDiscList());

    if (!result) {
        rootStore.dispatch(setNotification({ message: "Error retrieving Discipline list", notifyType: NotifyType.error }));
    }
    result && StorePunchlistDiscList(result);
    setLoader("REMOVE", "GetDiscListModel");
    return result
}

export async function GetCategoryListModel() {
    setLoader("ADD", "GetCategoryListModel");
    const api = navigator.onLine ? new PunchlistListApi() : new PunchlistListApiOffline();
    const result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.PunchlistGetCategoryList());

    if (!result) {
        rootStore.dispatch(setNotification({ message: "Error retrieving Category list", notifyType: NotifyType.error }));
    }
    result && StorePunchlistDiscList(result);
    setLoader("REMOVE", "GetCategoryListModel");

    return result
}

export async function GetAPListModel(idProject: number) {
    setLoader("ADD", "GetAPListModel");
    const api = navigator.onLine ? new PunchlistListApi() : new PunchlistListApiOffline();
    const result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.PunchlistGetAPList(idProject));

    if (!result) {
        rootStore.dispatch(setNotification({ message: "Error retrieving Action party list", notifyType: NotifyType.error }));
    }
    result && StorePunchlistAPList(result);
    setLoader("REMOVE", "GetAPListModel");

    return result
}

export async function GetInspItemListModel(idProject: number) {
    setLoader("ADD", "GetInspItemListModel");
    const api = navigator.onLine ? new PunchlistListApi() : new PunchlistListApiOffline();
    const result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.PunchlistGetInspItemList(idProject));

    if (!result) {
        rootStore.dispatch(setNotification({ message: "Error retrieving Inspection item list", notifyType: NotifyType.error }));
    }
    result && StorePunchlistInspItemList(result);
    setLoader("REMOVE", "GetInspItemListModel");
    return result
}

export async function GetPunchListTypeListModel() {
    setLoader("ADD", "GetPunchListTypeListModel");
    const api = navigator.onLine ? new PunchlistListApi() : new PunchlistListApiOffline();
    const result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.PunchlistGetPunchListTypeList());

    if (!result) {
        rootStore.dispatch(setNotification({ message: "Error retrieving Punchlist type list", notifyType: NotifyType.error }));
    }
    result && StorePunchlistInspItemList(result);
    setLoader("REMOVE", "GetPunchListTypeListModel");

    return result
}





