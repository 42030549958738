import ActionButtonIcon from "Components/GridSystem/ActionButtonIcon"
import image from '../../img/image.png'
import { DarkTheme } from '../../App';
import React, { useContext, } from 'react';
import '../../Css/ItrList.css';


interface IAttachments {
    title: string
    isNew: boolean
    index: number
    handleDownload: (id, idPL) => void
    handlePreview: (index) => void
    id?: number
    idPL?: number
}


const Attachments: React.FC<IAttachments> = ({ title, isNew, index, handleDownload, handlePreview, id, idPL }) => {

    const darkTheme = useContext(DarkTheme)

    return (
        <div className="d-flex py-lg-2 col-12 justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                <ActionButtonIcon buttonClassName="icon-md" imageClassName={darkTheme ? "bright" : ""} icon={image} />
                <p className={`m-0 ${darkTheme ? "bright" : ""}`} style={{ fontWeight: 'bold', }}  >{title}</p>
            </div>
            <p className={`m-0 ${darkTheme ? "bright" : ""}`} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}
                onClick={() => isNew ? handlePreview(index) : handleDownload(id, idPL)}>View</p>
        </div>
    )
}

export default Attachments

