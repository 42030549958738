import { DataLayout, GET_DATA_LAYOUT } from '../../Model/LayoutModel';

const initialState: DataLayout = {
    windowSize: null,
    breakpoint: null,
    isMobile: null
}

export const LayoutReducer = (state = initialState, action: { type: string, payload: DataLayout }) => {
    switch (action.type) {
        case GET_DATA_LAYOUT:
            {
                return { ...state, windowSize: action.payload.windowSize, breakpoint: action.payload.breakpoint, isMobile: action.payload.isMobile };
            }
        default:
            return state;
    }
};