
interface Loader {
	listOfCall: string[]
	show: boolean
}

const initialState: Loader = {
	listOfCall: [],
	show: false,
};

export const LoaderReducer = (state = initialState, action: { type: string; payload: string }) => {
	const copy = { ...state };

	switch (action.type) {
		case "ADD":
			copy.listOfCall.push(action.payload);
			copy.show = true;
			return copy;

		case "REMOVE":
			const idx = copy.listOfCall.indexOf(action.payload);
			copy.listOfCall.splice(idx, 1);
			copy.show = copy.listOfCall.length === 0 ? false : true;
			return copy;
			
		default:
			return state;
	}
};
