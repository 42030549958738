import React from 'react';
import FiltersSelect from '../../Components/FormAndFilters/FiltersAutogen/FiltersSelect';
import { QueryObject } from '../../Model/CommonModel';
import { FilterResource } from './useFilterCustom';
import FiltersBooleanDoubleRadio from '../../Components/FormAndFilters/FiltersAutogen/FiltersBooleanDoubleRadio';
import FiltersMultiRadio from 'Components/FormAndFilters/FiltersAutogen/FiltersMultiRadio';


export function SelectFilterType(type: number, selectAction: any, booleanRadioFilterAction: any,multiRadioFilterAction:any, propertyName: string, propertyText: string, filtersSelected: QueryObject | undefined, filterResource: FilterResource[]) {

    switch (type) {
        case 2:
            const resourceSelect = filterResource.find(x => x.propertyName === propertyName)?.filterList
            const selectedValue = filtersSelected && filtersSelected[propertyName] as string[] | undefined
            const selectedRow = filtersSelected && filtersSelected[propertyName] as (string | number)[] | undefined
            if (propertyName !== 'statusPL') {
                return (
                    <FiltersSelect
                        key={`${propertyName}`}
                        action={selectAction}
                        data={{ property: propertyName, selected: selectedRow, options: resourceSelect ?? [] }}
                        settings={{ label: propertyText }}>
                    </FiltersSelect>
                )
            } else {
                return (
                    <FiltersMultiRadio
                        key={`${propertyName}`}
                        action={multiRadioFilterAction}
                        data={{ property: propertyName, value: (selectedValue && selectedValue[0]) }}
                        settings={{
                            label: propertyText,
                            overrideLabels: ["Raised", "Cleared", "Accepted", "Rejected"],
                        }}>
                    </FiltersMultiRadio>
                )
            }
        case 7:
            const selectedValueBoolean = filtersSelected && filtersSelected[propertyName] as boolean | undefined
            return (
                <FiltersBooleanDoubleRadio
                    key={`${propertyName}`}
                    action={booleanRadioFilterAction}
                    data={{ property: propertyName, value: selectedValueBoolean }}
                    settings={{
                        label: propertyText,
                        overrideLabels: propertyName === "offline" ? ["Offline", "Online"] : undefined,
                        hiddenFilter: propertyName === "offline" ? !navigator.onLine : false
                    }}>
                </FiltersBooleanDoubleRadio>
            )
        default:
            return
    }
}