export const authority = "https://login.microsoftonline.com/c16e514b-893e-4a01-9a30-b8fef514a650";
export const tenantId = "c16e514b-893e-4a01-9a30-b8fef514a650";
export const swFile = "service-worker-localhost.js";

export type ConfigEntry = {
    clientId: string;
    redirectUri: string;
    scopes: string[];
    apiUrl: string;
}

export const environment: Record<string, ConfigEntry> = {
    // 'localhost:3000': {
    //     clientId: "95cd2bb7-2e49-4c9c-99a5-3951e4043736",
    //     redirectUri: "https://localhost:3000",
    //     scopes: ["api://65a09eab-cfd2-46a7-8826-09b96c7100ef/Access.All"], 
    //     apiUrl: "http://localhost:5000/api/",
    // }    
    'localhost:3000': {
        clientId: "74aa7595-7150-479f-a3cf-079d6f30e8d6",
        redirectUri: "https://localhost:3000",
        scopes: ["api://1e7c2050-a940-4442-a04a-1810c348ef90/Access.All"], 
        apiUrl: "http://localhost:5000/api/"    
    },
    'sd-ccms-mobile.eni.com': {
        clientId: "68375c3a-fca8-4d92-8128-ec36c2e82a60",
        redirectUri: "https://sd-ccms-mobile.eni.com",
        scopes: ["api://b8c21a79-0e2a-4cd4-8f28-4568eeb7a37a/Access.All"], 
        apiUrl: "https://api-app.eni.com/sd-ccms-mobile/ccms-mobile-be-ccmsmobile/api/"
    }, 
    'st-ccms-mobile.eni.com': {
        clientId: "39449c5c-ef64-400a-9002-4010ea1fc528",
        redirectUri: "https://st-ccms-mobile.eni.com",
        scopes: ["api://6623a548-23be-4a97-b824-e0425659b32e/Access.All"], 
        apiUrl: "https://api-app.eni.com/st-ccms-mobile/ccms-mobile-be-ccmsmobile/api/"
    }, 
    'ccms-mobile.eni.com': {
        clientId: "b1a1e8cb-d24a-4e99-b085-97014641b012",
        redirectUri: "https://ccms-mobile.eni.com", 
        scopes: ["api://ca7cb4b5-18c6-41db-b634-5864a92f4d02/Access.All"], 
        apiUrl: "https://api-app.eni.com/pr-ccms-mobile/ccms-mobile-be-ccmsmobile/api/"
    }            
}