export const stringIsNullOrEmpty = (text: string | undefined) => {
    return text === null || text === undefined || text === "";
};

export const numberIsNullOrZero = (number: number | undefined) => {
    return number === null || number === undefined || number === 0;
};

export const arrayIsNullOrEmpty = (list: Array<any> | undefined) => {
    return list === null || list === undefined || list.length === 0
}

export function lowerFirstLetter(string) {
	return string.charAt(0).toLowerCase() + string.slice(1);
}
export function upperFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toggleStateNumber = (state: number, newState: number) => {
	return state === newState ? 0 : newState;
};

export const toggleStateString = (state: string, newState: string) => {
	return state === newState ? "" : newState;
};

export const setCssClass = (id: string, className: string) => {
    const target = document.getElementById(id)
    if (target !== undefined) {
      target?.setAttribute("class", className)
    }
  }