import React, { useContext, useState } from 'react';
import '../../Css/Index.css'
import '../../Css/ItrList.css'
import InputSearch from '../../Components/FormAndFilters/FiltersFullTextInputSearch';
import FiltersMenuIconButton from '../../Components/FormAndFilters/FiltersMenuIconButton';
import { GetAddResultToGrid, GetItrModel } from '../../Redux/Action/ItrList/ItrListGridAction';
import { useResourceTableCrud } from '../../Hook/Grid/useResourceTableCrud';
import { ItrModelQuery, ItrModel, GET_ITR_DETAIL, ItrModelDetail } from '../../Model/ItrList';
import Pagination from '../../Components/PaginationComponent';
import { RootState, rootStore } from '../../Redux/Store/rootStore';
import { useSelector } from 'react-redux';
import ItrsListGrid from '../ItrsList/ItrsListGrid';
import ItrsListDetail from '../ItrsList/ItrsListDetail';
import { arrayIsNullOrEmpty } from '../../Hook/Common/Utility';
import SelectProjectMenu from '../ItrsList/SelectProjectMenu';
import { useEffect } from 'react';
import ModalConfirm from '../../Components/Various/ModalConfirm';
import { stateConfirm, DataModalConfirm } from '../../Model/CommonModel';
import { Online } from '../../Components/OnlineOffline';
import '../../img/left-chevron.png'
import '../../img/eye.png'
import { DarkTheme } from '../../App';
// import { GET_PL_DROPDOWN_OPTIONS, PunchlistDropdownOptions } from 'Model/PunchlistList';
// import { GetDiscListModel, GetCategoryListModel, GetPunchListTypeListModel, GetAPListModel, GetInspItemListModel } from 'Redux/Action/PunchlistList/PunchlistListGridAction';

interface Props {
  isOnline: boolean
}

const ItrsList: React.FC<Props> = (props) => {

  const projectCode = localStorage.getItem('projectCode')
  const isEdited = localStorage.getItem("isEdited")

  const darkTheme = useContext(DarkTheme)

  const initialState: ItrModelQuery = {
    fullText: "",
    itrCode: [],
    projectCode: projectCode !== null ? [projectCode as string] : [],
    itemCode: [],
    discipline: [],
    itrType: [],
    status: [],
    date: undefined,
    sortBy: "",
    isSortAscending: false,
    page: 1,
    pageSize: 15,
    principalId: undefined,
    orphan: false,
    deleted: false,
    lastModified: undefined,
    lastModifiedBy: [],
    offline: !navigator.onLine,
  }

  useEffect(() => {
    if (props.isOnline === false)
      setQuery(query, true)
  }, [props.isOnline])

  //UTILITY
  const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm)

  //REDUCERS
  const GridData = (state: RootState) => state.itrListGridReducer.gridResult;
  const GridDto = useSelector(GridData);

  const LayoutData = (state: RootState) => state.layoutReducer;
  const layout = useSelector(LayoutData);

  const downloadedData = (state: RootState) => state.itrListDownloadReducer.dtoDownload;
  const downloadedList = useSelector(downloadedData);

  //DETAIL
  const [isDetailOpened, setIsDetailOpened] = useState<boolean>(false)
  
  const [isClosedSelected, setIsClosedSelected] = useState<boolean|undefined>(false)
  const [plAdded, setPlAdded] = useState<number | undefined>(undefined)

  const openUpdatedDetail = async (id: number,isClosedSelected?:boolean) => {
    setPlAdded(id)
    setIsClosedSelected(isClosedSelected)
    setIsDetailOpened(false)
    setQuery(initialState, true)
  }

  useEffect(()=>{
    if(isEdited === "true"){
      setQuery(initialState, true)
      localStorage.setItem("isEdited",`false`)
    }
  },[isEdited])

  useEffect(() => {
    if (plAdded !== undefined) {
      const item = GridDto?.items?.filter(x => x.id === plAdded)[0]
      rootStore.dispatch({ type: GET_ITR_DETAIL, payload: { dtoEdit: item, resultDto: null } as ItrModelDetail });
      !isClosedSelected && setIsDetailOpened(true)
      setPlAdded(undefined)
    }
  }, [GridDto])


  const openDetail = async (item: ItrModel) => {
    rootStore.dispatch({ type: GET_ITR_DETAIL, payload: { dtoEdit: item, resultDto: null } as ItrModelDetail });
    setIsDetailOpened(true)
  }

  const closeDetail = (changed: boolean) => {
    if (changed) {
      setQuery(initialState, true)
    }
    setIsDetailOpened(false)
  }


  //FILTERS
  const { query, setQuery, next, back } = useResourceTableCrud(initialState, GetItrModel, layout.isMobile ?? true, GetAddResultToGrid, GridDto?.items);

  return (
    <div className={`col-12 px-0 mt-3 row mx-0 relative h-100 pageContainer justify-content-center ${darkTheme ? "dark" : ""}`}>
      <ModalConfirm data={confirm} />
      {isDetailOpened ?
        <ItrsListDetail isOnline={props.isOnline} action={{ backAction: closeDetail, setConfirm, addPlAction: openUpdatedDetail }} />
        : !arrayIsNullOrEmpty(query['projectCode']) ?
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between col-12">
              <h2 className="mb-0">ITRs/Item list</h2>
              <FiltersMenuIconButton containerClass={""} initialStateQuery={initialState} query={query} propertyToNotOverride={"projectCode"} action={{ applyFilters: setQuery }} renderGrid={GridDto?.gridRender?.render} />
            </div>
            <div className="col-12 mt-3">
              <InputSearch propertyName={"fullText"} query={query} action={{ Submit: setQuery }} placeHolder="Find ITR or Inspection items"></InputSearch>
            </div>
            <div className={`col-12 h-100 paginationScrollable`}>
              <ItrsListGrid action={{ openDetail, setConfirm, setQuery }} data={GridDto?.items} downloadedList={downloadedList} query={query} renderGrid={GridDto?.gridRender?.render} />
              <Online>
                <Pagination isMobile={layout.isMobile ?? false} pagination={{ page: query.page, pageSize: query.pageSize }} totalItems={GridDto?.totalItems ?? 0} itemsLength={GridDto?.items?.length ?? 0} actions={{ next, back }} />
              </Online>
            </div>
          </div>
          :
          <SelectProjectMenu query={query} initialState={initialState} action={{ applyFilters: setQuery }} />
      }
    </div>
  );
}
export default ItrsList
