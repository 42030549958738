import localforage from "localforage";
import {CustomGridRender, GRID_RENDER} from "../../Model/CommonModel"
import { setNotification } from "../../Redux/Action/NotificationAction";
import { NotifyType } from "../../Redux/Reducer/NotificationReducer";
import { rootStore } from "../../Redux/Store/rootStore";



export const StoreGridRender = (gridRender: CustomGridRender,unicheKey : string ) => {
  localforage.setItem(GRID_RENDER + unicheKey,gridRender);
}

export const GetGridRender = async (unicheKey : string ) :Promise<CustomGridRender> => {
 return await localforage.getItem(GRID_RENDER + unicheKey) as CustomGridRender;
}

export const ClearLocalForge = (showNotification? : boolean)=> {
  localforage.clear();
  rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: [] });
  showNotification === true &&
  rootStore.dispatch(setNotification({ message: "Offline Database is Empty!", notifyType: NotifyType.noNotify }));
}
