import { CustomGridRender, DateFilter, FilterValueDto, QueryObject, ResultDto } from './CommonModel';

export interface PunchlistModel {
    idUniquePL: number;
    idUniqueITR: number;
    itrGroup: string;
    plItemItem: number;
    inspItemCode: string;
    plDescription: string;
    idSubsystem: number;
    systemCode: string;
    subsystemCode: string;
    categoryCode: string;
    disciplineCode: string;
    actionPartyCode: string;
    plTypeCode: string;
    raisedBy: string;
    raisedDate: Date;
    clearedBy: string;
    clearedDate: Date;
    acceptedBy: string;
    acceptedDate: string;
    statusPL: string;
    fileName: string,
    on: string,
    attachments: Attachment[]
}

export interface Attachment {
    idAttachment: number;
    fileName: string;
}

export interface PunchlistModelQuery extends QueryObject {
    fullText?: string
    disciplineCode?: string[]
    idSubsystem?: string[]
    statusPL?: string[]
    projectCode?: string[],
}

export interface PunchlistAddUpdateModel {
    idUniqueITR :string;
    idInspItem: string;
    idCategory: string;
    idPunchListType: string;
    description: string;
    idActionParty: string;
    idDisciplina: string;
    attachment: File[];
}

export interface ApprovedModel {
    id: number
    file?: Blob
}

export interface PunchlistModelGrid {
    gridResult: PunchlistListQueryResultDto | null
    filter: FilterValueDto[] | null
}

export interface PunchlistModelDetail {
    dtoEdit: PunchlistModel | null,
    resultDto: ResultDto | null,
}
export interface PunchlistModelDownloaded {
    dtoDownload: number[],
}

export interface PunchlistDropdownOptions {
        plTypeOptions: FilterValueDto[] | null,
        inspItemOptions: FilterValueDto[] | null,
        categoryOptions: FilterValueDto[] | null,
        disciplineOptions: FilterValueDto[] | null,
        actionPartyOptions: FilterValueDto[] | null,
}

export interface PunchlistListQueryResultDto {
    /**
     *
     * @type {number}
     * @memberof PunchlistListQueryResultDto
     */
    totalItems?: number;
    /**
     *
     * @type {Array<PunchlistList>}
     * @memberof PunchlistListQueryResultDto
     */
    items?: Array<PunchlistModel>;
    /**
     *
     * @type {CustomGridRender}
     * @memberof PunchlistListQueryResultDto
     */
    gridRender?: CustomGridRender;
    status?:number
}

export const GET_PL_GRID = "GET_PL_GRID"
export const ADD_PL_GRID = "ADD_PL_GRID"
export const GET_PL_FILTERS = "GET_PL_FILTERS"
export const GET_PL_DISCS = "GET_PL_DISCS"
export const GET_PL_CATEGORIES = "GET_PL_CATEGORIES"
export const GET_PL_AP = "GET_PL_AP"
export const GET_PL_INSPITEM = "GET_PL_INSPITEM"
export const GET_PL_DETAIL = "GET_PL_DETAIL"
export const GET_PL_PUNCHLIST_TYPE = "GET_PL_PUNCHLIST_TYPE"
export const GET_PL_DROPDOWN_OPTIONS = "GET_PL_DROPDOWN_OPTIONS"
export const EDIT_PL = "EDIT_PL"





//#region offline definition

export const PL_LOCAL = "PL_LOCAL"
export const PL_FILTERS_LOCAL = "PL_FILTERS_LOCAL"
export const PL_DISCS_LOCAL = "PL_DISCS_LOCAL"
export const PL_CATEGORIES_LOCAL = "PL_CATEGORIES_LOCAL"
export const PL_AP_LOCAL = "PL_AP_LOCAL"
export const PL_INSPITEM_LOCAL = "PL_INSPITEM_LOCAL"
export const PL_PUNCHLIST_TYPE_LOCAL = "PL_PUNCHLIST_TYPE_LOCAL"
export const PL_GRIDRENDER = "PL_GRIDRENDER"

//#endregion
