import { EDIT_ITR, GET_ITR_DETAIL, ItrModelDetail } from '../../../Model/ItrList';

const initState: ItrModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const ItrListDetailReducer = (state = initState, action: { type: string, payload: ItrModelDetail }) => {
    switch (action.type) {
        case GET_ITR_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_ITR:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}