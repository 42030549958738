import React, { useContext } from 'react';
import '../../Css/ItrList.css'
import { SelectGridPropertyItem } from '../../Hook/Grid/SelectGridPropertyItem';
import { PunchlistModel, PunchlistModelQuery, PL_LOCAL } from '../../Model/PunchlistList';
import { RenderDetail, DataModalConfirm } from '../../Model/CommonModel';
import { setCssClass, arrayIsNullOrEmpty } from '../../Hook/Common/Utility';
import { rootStore } from '../../Redux/Store/rootStore';
import localforage from 'localforage';
import { useEffect } from 'react';

import { DarkTheme } from '../../App';

interface Props {
  action: {
    openDetail(item: PunchlistModel): any;
    setConfirm(data: DataModalConfirm): any
    setQuery?(query: PunchlistModelQuery, forceClassicPagination?: boolean): any
  }
  renderGrid: RenderDetail[] | undefined;
  data: PunchlistModel[] | undefined
  isAssociated?: boolean
  // downloadedList: number[]
}

const PunchlistsListGrid: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme);
  const elements = props.data ?? [];
  const fileInputRefs = {}
  elements.forEach((e) => {
    fileInputRefs[e.idUniquePL] = React.createRef();
  })

  useEffect(() => {
    async function getLocalForage() {
      const list = await localforage.getItem(PL_LOCAL) as PunchlistModel[]
      const arr = [] as number[]
      if (!arrayIsNullOrEmpty(list)) {
        list?.map(x => {
          arr.push(x.idUniquePL)
        })
        rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
      }
    }
    getLocalForage()
  }, [])

  return (
    <div className="w-100 pt-4 row mx-0">
      <div className="col-12 px-0">
        {/* LIST ITEM */}
        {!arrayIsNullOrEmpty(props.data) ? props.data?.map((item, i) => (
          <div className={`itr-item ${darkTheme ? "dark" : ""}`} key={`${item.idUniquePL}-${i}`} id={`itemList${item.idUniquePL}`}>
            <div className='flex-column col-12 clickable'
              onMouseEnter={() => setCssClass(`itemList${item.idUniquePL}`, `itr-item ${darkTheme ? "dark hover" : "hover"}`)}
              onMouseLeave={() => setCssClass(`itemList${item.idUniquePL}`, `itr-item ${darkTheme ? "dark" : ""}`)}
              onClick={() => props.action.openDetail(item)}>
              <h5 className='bold' style={{ paddingLeft: '15px' }}>Punchlist item {item.idUniquePL}</h5>
              <div className="col clickable row mx-0">
                {props.renderGrid?.filter(x => x.show === true)?.map((prop, propIndex) => (
                  SelectGridPropertyItem(
                    item[prop.propertyName],
                    prop.propertyName,
                    prop.propertyName === "statusTxt" || prop.propertyName === "date" ? "" : prop.propertyText,
                    prop.type,
                    i,
                    "mb-0",
                    "mb-0")
                ))}
              </div>
            </div>
          </div>
        ))
          :
          <div className="w-100 my-2">
            {
              props.isAssociated ?
                <h5>No punchlist items associated to this ITR</h5>
                :
                <h5>The search did not return any results</h5>
            }
          </div>
        }
      </div>
    </div>
  );
}
export default PunchlistsListGrid
