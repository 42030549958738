import React from "react";
import '../../../Css/Filters.css'
import '../../../Css/Index.css'
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';

interface Props {
    action: {
        onChange(prop: string, value: string): any,
    }
    data: {
        property: string
        value: string | undefined
    }
    settings: {
        label?: string
        isDisabled?: boolean
        hiddenFilter?: boolean
        verticalLayout?: boolean
        overrideLabels?: string[]
        containerClass?: string
        labelClass?: string
        inputClass?: string
    }
}


const FiltersMultiRadio: React.FC<Props> = (props) => {

    return (
        <div className={`col-12 col-md-8 col-lg-4 d-flex ${props.settings.containerClass}`}>
            <div className="form-group">
                <label className="labelForm w-100 text-white text-uppercase">
                    {props.settings.label}
                    <div className={`d-flex ${props.settings.verticalLayout === true ? "flex-column" : "align-items-center"} row mx-0`}>
                        {
                            props.settings.overrideLabels?.map((item) => {
                                return (
                                    <div key={item} className="pr-3 py-1">
                                        <label className={`fakeCheckbox ${props.settings.labelClass} d-flex flex-nowrap align-items-center`}>
                                            <input type="checkbox" onChange={() => props.action.onChange(props.data.property, item)} checked={props.data.value === item}></input>
                                            <div className={`fakeCheckbox ${props.settings.inputClass} mr-1 ${props.data.value === item ? "checked" : ""}`}></div>
                                            {arrayIsNullOrEmpty(props.settings.overrideLabels) ? "NO" : props.settings.overrideLabels && item}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                </label>
            </div>
        </div>
    )
}
export default FiltersMultiRadio