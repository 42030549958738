import React from "react";
import '../../Css/Index.css'
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';

interface Props {
    property: string
    label?: string
    value: string | number | Date | undefined
    valueClassName?: string
    labelClassName?: string
    isBlue?: boolean
    index: number
}

const PropertyItem: React.FC<Props> = (props) => {

    return (
        <div className="d-flex col-12 col-lg flex-nowrap align-items-center row mx-0 px-0 py-1 pr-lg-4 propertyItemText">
            {!stringIsNullOrEmpty(props.label) ?
                <label className={`${props.labelClassName} bold pr-2 text-nowrap`}>{props.label}:</label>
                : null}
            <label style={props.isBlue ? { color: 'rgb(0, 125, 228)' } : {}} className={`${props.valueClassName} text-nowrap`}>{props.value ?? "---"}</label>
        </div>
    )
}
export default PropertyItem