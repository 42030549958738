export interface DataLayout {
    windowSize: WindowSizeData | null
    breakpoint: string | null
    isMobile: boolean | null
}

export interface WindowSizeData {
    height: number | null
    width: number | null
}

export const GET_DATA_LAYOUT = "GET_DATA_LAYOUT"
