
import { CustomGridRender, FilterValueDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeaderAsync } from "./Common/configuration";
import { ITR_GRIDRENDER, ITR_LOCAL, ItrListQueryResultDto, ItrModel, ItrModelQuery, ApprovedModel, ITR_FILTERS_LOCAL } from '../Model/ItrList';
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";
import { stringIsNullOrEmpty } from '../Hook/Common/Utility';

export class ItrListApi {

  ItrListGetGrid = async (tableQuery: ItrModelQuery): Promise<ItrListQueryResultDto> => {
    const options = {
      method: "POST",
      headers: await GetHeaderAsync(),
      body: JSON.stringify(tableQuery),
    };
    const response = await fetch(basePath + "ITR/Get", options);
    return await response.json() as ItrListQueryResultDto;
  }

  ItrListDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeaderAsync(),
    };
    const url = basePath + "ITR/File?id=" + id;
    const response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  ItrListGetFilters = async (columName: string, columValue?: string, queryFilter?: ItrModelQuery): Promise<FilterValueDto[]> => {
    const options = {
      method: "POST",
      headers: await GetHeaderAsync(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "ITR/Filter?propertyName=" + columName;

    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }

    const response = await fetch(url, options);
    return await response.json() as FilterValueDto[];
  }

  ItrItemChangeStatus = async (model: ApprovedModel): Promise<ResultDto> => {
    const formData = new FormData();
    formData.append("id", model.id.toString())

    if (model.file)
      formData.append("file", model.file)

    const options = {
      method: "POST",
      headers: await GetHeaderAsync(true),
      body: formData
    };

    const url = basePath + "ITR/ChangeState";

    const response = await fetch(url, options);
    return await response.json() as ResultDto;
  }

  ItrItemChangeStatusRejected = async (model: ApprovedModel): Promise<ResultDto> => {
    const formData = new FormData();
    formData.append("id", model.id.toString())

    if (model.file)
      formData.append("file", model.file)

    const options = {
      method: "POST",
      headers: await GetHeaderAsync(true),
      body: formData
    };

    const url = basePath + "ITR/ChangeStateReject";

    const response = await fetch(url, options);
    return await response.json() as ResultDto;
  }

  ItrItemReject = async (id: number): Promise<ResultDto> => {

    const options = {
      method: "DELETE",
      headers: await GetHeaderAsync(),
    };

    const url = basePath + "ITR?id=" + id;

    const response = await fetch(url, options);
    return await response.json() as ResultDto;
  }
}


export class ItrListApiOffline {


  ItrListGetGrid = async (tableQuery: ItrModelQuery): Promise<ItrListQueryResultDto> => {

    let items: ItrModel[] | null = await localforage.getItem(ITR_LOCAL);
    const gridRender: CustomGridRender = await GetGridRender(ITR_GRIDRENDER);

    if (!stringIsNullOrEmpty(tableQuery.fullText)) {
      items = items?.filter(x => x.itemCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "") || x.itrCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "")) ?? []
    } else {
      items = items
    }
    tableQuery.discipline?.forEach(s => {
      items = items?.filter(x => x.discipline === s) ?? []
    });
    tableQuery.itrType?.forEach(s => {
      items = items?.filter(x => x.itrType === s) ?? []
    });
    const res: ItrListQueryResultDto = { gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length }
    return res;
  }

  ItrListDownload = async (id: number): Promise<ReturnFile> => {

    const options = {
      method: "GET",
      headers: await GetHeaderAsync(),
    };
    const url = basePath + "ITR/File?id=" + id;
    const response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  ItrListGetFilters = async (columName: string, columValue?: string, queryFilter?: ItrModelQuery): Promise<FilterValueDto[]> => {
    const items: FilterValueDto[] | null = await localforage.getItem(ITR_FILTERS_LOCAL + columName);
    return items ?? [];
  }
}

