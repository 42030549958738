import React from "react";
import { Modal } from "react-bootstrap";
import "../../Css/Index.css";
import { DataModalImgPreview } from "../../Model/CommonModel";
import close from '../../img/close.png'

export interface Props {
	data: DataModalImgPreview;
}


const ModalImgPreview: React.FC<Props> = (props) => {
	const data = props.data;

	return (
		<Modal show={data.isOpen} onHide={data.actions.cancel} backdrop="static" backdropClassName="backdropConfirm" dialogClassName="dialogConfirm" className="modalConfirm" keyboard={false} size="lg" centered>
			<Modal.Body>
				<div className="col-12 px-0 text-center d-flex flex-column">
					<div className="w-100 text-left">
						<button type="button" className="btn btn-icon trasparent-bg icon-sm" onClick={() => data.actions.cancel()}>
							<img style={{width:'100%'}} alt="close" src={close}></img>
						</button>
					</div>
					<h3 className="bold">{data.title}</h3>
				</div>

				<div className="col-12 px-0 text-center py-3">
					<img style={{width:'100%'}} src={data.img} alt="" />
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalImgPreview;
