/**
 *
 * @export
 * @interface QueryObject
 */
export interface QueryObject {
    /**
     *
     * @type {string}
     * @memberof QueryObject
     */
    sortBy?: string;
    /**
     *
     * @type {boolean}
     * @memberof QueryObject
     */
    isSortAscending?: boolean;
    /**
     *
     * @type {number}
     * @memberof QueryObject
     */
    page?: number;
    /**
     *
     * @type {number}
     * @memberof QueryObject
     */
    pageSize?: number;
    /**
     *
     * @type {DateFilter}
     * @memberof QueryObject
     */
    lastModified?: DateFilter;
    /**
     *
     * @type {number}
     * @memberof QueryObject
     */
    principalId?: number;
    /**
     *
     * @type {boolean}
     * @memberof QueryObject
     */
    deleted?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof QueryObject
     */
    orphan?: boolean;
    /**
     *
     * @type {string}
     * @memberof QueryObject
     */
    lastModifiedBy?: Array<string>;
}

/**
 *
 * @export
 * @interface DateFilter
 */
export interface DateFilter {
    /**
     *
     * @type {Date}
     * @memberof DateFilter
     */
    startDate?: Date;
    /**
     *
     * @type {Date}
     * @memberof DateFilter
     */
    endDate?: Date;
}

/**
 *
 * @export
 * @interface FilterValueDto
 */
export interface FilterValueDto {
    /**
     *
     * @type {string}
     * @memberof FilterValueDto
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof FilterValueDto
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof FilterValueDto
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof FilterValueDto
     */
    dto?: any;
}

/**
 *
 * @export
 * @interface ResultDto
 */
export interface ResultDto {
    /**
     *
     * @type {boolean}
     * @memberof ResultDto
     */
    warning?: boolean;
    /**
     *
     * @type {string}
     * @memberof ResultDto
     */
    info?: string;
    /**
     *
     * @type {ModelObject}
     * @memberof ResultDto
     */
    data?: any;
}

export interface CustomGridRender {
    /**
     *
     * @type {string}
     * @memberof CustomGridRender
     */
    className: string;
    /**
     *
     * @type { Array<RenderDetail>}
     * @memberof RenderDetail
     */
    render: Array<RenderDetail>;
}

export interface FileResult {
    file: Blob;
    fileName: string;
}
export interface ReturnFile {
    File: Promise<Blob>;
    FileName: string;
}

/**
 *
 * @export
 * @interface RenderDetail
 */
export interface RenderDetail {
    /**
     *
     * @type {string}
     * @memberof RenderDetail
     */
    propertyName: string;
    /**
    *
    * @type {string}
    * @memberof RenderDetail
    */
    propertyText: string;
    /**
     *
     * @type {boolean}
     * @memberof RenderDetail
     */
    show: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RenderDetail
     */
    showFilter: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RenderDetail
     */
    ignore: boolean;
    /**
     *
     * @type {number}
     * @memberof RenderDetail
     */
    order: number;
    /**
     *
     * @type {GridFilterType}
     * @memberof RenderDetail
     */
    type: GridFilterType;
    /**
    *
    * @type {string}
    * @memberof RenderDetail
    */
    tab?: string;
    /**
    *
    * @type {string}
    * @memberof RenderDetail
    */
    colorHeader?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum GridFilterType {
    CheckBoxFilter = 0 as any,
    CheckBoxFilterNumber = 1 as any,
    DateRangeFilter = 2 as any,
}

export interface DataModalConfirm {
    title: string;
    message: string;

    buttonText: string;
    secondaryButtonText?: string;
    cancelText?: string;
    hideCancel?: boolean;
    isOpen: boolean;
    onlyOneButton?: boolean;
    actions: {
        confirm(): any;
        secondaryConfirm?(): any;
        cancel(): any;
    };
}
export interface DataModalConfirm {
    title: string;
    message: string;

    buttonText: string;
    secondaryButtonText?: string;
    cancelText?: string;
    hideCancel?: boolean;
    isOpen: boolean;
    onlyOneButton?: boolean;
    actions: {
        confirm(): any;
        secondaryConfirm?(): any;
        cancel(): any;
    };
}

export interface DataModalImgPreview {
    title: string;
    img: string;
    isOpen: boolean;
    actions: {
        cancel(): any;
    };
}

export const stateConfirm = {
    title: "",
    buttonText: "",
    message: "",
    isOpen: false,
    actions: {
        cancel: () => {
            return;
        },
        confirm: () => {
            return;
        },
    },
};



export const GRID_RENDER = "GRID_RENDER";
