import React, { useContext } from "react";
import { Offline, Online } from "../OnlineOffline";
import ButtonLinkIconTextHoriz from "../Various/ButtonLinkIconTextHoriz";
import list from '../../img/itrList.png'
import punchList from '../../img/punchList.png'
import actions from '../../img/actions.png'
import archive from '../../img/archive.png'
import profile from '../../img/profile.png'
import { DarkTheme } from "../../App";

const NavHeader: React.FC = (props) => {

  const darkTheme = useContext(DarkTheme)

  return (
    <div className={`col-12 px-0 row mx-0 headerMobileContainer ${darkTheme ? "dark" : ""} d-flex align-items-center`}>
      <div className="col-5 d-flex align-items-center pl-4 pl-xl-5">
        <img alt="logo" title="ENI" height="40" src={require('../../svg/Logo.svg').default}></img>
        <h5 className="mr-2 mb-0 mt-2 ml-2" style={{ fontWeight: 'bold' }}>EMIMS</h5>
        <h5 className="mb-0 mt-2" style={{ fontWeight: 'normal' }}>CCMS</h5>
      </div>

      <div className="col-7 trapezoidHeader">
      </div>

      <div className="col-7 d-flex align-items-center justify-content-around pr-4 pr-lg-5">
        <div className="d-flex row mx-0 col pl-5">
          <ButtonLinkIconTextHoriz disabled={false} label="ITRs list" path="/" icon={list}></ButtonLinkIconTextHoriz>
          <ButtonLinkIconTextHoriz disabled={false} label="Punchlist" path="/punchlist" icon={punchList}></ButtonLinkIconTextHoriz>
          <ButtonLinkIconTextHoriz disabled={true} label="Actions" path="/aa" icon={actions}></ButtonLinkIconTextHoriz>
          <ButtonLinkIconTextHoriz disabled={false} label="Archive" path="/archive" icon={archive}></ButtonLinkIconTextHoriz>
          <ButtonLinkIconTextHoriz disabled={false} label="Profile" path="/profile" icon={profile}></ButtonLinkIconTextHoriz>
        </div>
        <div className="d-flex flex-row align-items-center">
          <Online>
            <div className="logStatusIcon online mr-2"></div>
            <label className="logStatusText mb-0">Online</label>
          </Online>
        </div>
        <div className="d-flex flex-row align-items-center">
          <Offline>
            <div className="logStatusIcon offline mr-2"></div>
            <label className="logStatusText mb-0">Offline</label>
          </Offline>
        </div>
      </div>
    </div>
  )
}

export default NavHeader
