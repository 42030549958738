import '../../Css/Index.css';
import '../../Css/ItrList.css';
import localforage from 'localforage';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InputSearch from '../../Components/FormAndFilters/FiltersFullTextInputSearch';
import FiltersMenuIconButton from '../../Components/FormAndFilters/FiltersMenuIconButton';
import { useResourceTableCrud } from '../../Hook/Grid/useResourceTableCrud';
import { GET_ITR_GRID, ITR_LOCAL, ItrModel, ItrModelGrid, ItrModelQuery, GET_ITR_DETAIL, ItrModelDetail } from '../../Model/ItrList';
import { GetAddResultToGrid, GetItrModel } from '../../Redux/Action/ItrList/ItrListGridAction';
import { RootState, rootStore } from '../../Redux/Store/rootStore';
import ArchiveGrid from '../Archive/ArchiveGrid';
import ItrsListDetail from '../ItrsList/ItrsListDetail';
import { DataModalConfirm, stateConfirm } from '../../Model/CommonModel';
import ModalConfirm from '../../Components/Various/ModalConfirm';
import { DarkTheme } from '../../App';


interface Props {
  isOnline: boolean
}

const ArchiveContainer: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  const initialState: ItrModelQuery = {
    fullText: "",
    itrCode: [],
    projectCode: [],
    itemCode: [],
    discipline: [],
    itrType: [],
    status: [],
    date: undefined,
    sortBy: "",
    isSortAscending: false,
    page: 1,
    pageSize: 15,
    principalId: undefined,
    orphan: false,
    deleted: false,
    lastModified: undefined,
    lastModifiedBy: [],
    offline: true,
  }

  //DATA
  useEffect(() => {
    async function getLocal() {
      const dto = await localforage.getItem(ITR_LOCAL) as ItrModel[]
      rootStore.dispatch({ type: GET_ITR_GRID, payload: { gridResult: dto, filter: null } as ItrModelGrid });
    }
    getLocal();
  }, [])

  //UTILITY
  const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm)

  //REDUCERS
  const GridData = (state: RootState) => state.itrListGridReducer.gridResult;
  const GridDto = useSelector(GridData);

  const LayoutData = (state: RootState) => state.layoutReducer;
  const layout = useSelector(LayoutData);

  //DETAIL
  const [isDetailOpened, setIsDetailOpened] = useState<boolean>(false)

  const openDetail = async (item: ItrModel) => {
    rootStore.dispatch({ type: GET_ITR_DETAIL, payload: { dtoEdit: item, resultDto: null } as ItrModelDetail });
    setIsDetailOpened(true)
  }

  const closeDetail = () => {
    setQuery(query, true)
    setIsDetailOpened(false)
  }

  //FILTERS
  const { query, setQuery } = useResourceTableCrud(initialState, GetItrModel, layout.isMobile ?? true, GetAddResultToGrid, GridDto?.items);

  return (
    <div className={`col-12 px-0 mt-3 row mx-0 relative h-100 pageContainer ${darkTheme ? "dark": ""}`}>
      <ModalConfirm data={confirm} />

      {isDetailOpened ?
        <ItrsListDetail isOnline={props.isOnline} buttonHidden={true} action={{ backAction: closeDetail, setConfirm, addPlAction:()=>console.log() }} />
        :
        <div className="w-100">
          <div className="d-flex align-items-center justify-content-between col-12">
            <h2 className="mb-0">Download history</h2>
            <FiltersMenuIconButton initialStateQuery={initialState} query={query} propertyToNotOverride={"projectCode"} action={{ applyFilters: setQuery }} renderGrid={GridDto?.gridRender?.render?.filter(x => x.propertyName !== "offline")} />
          </div>
          <div className="col-12 mt-3">
            <InputSearch propertyName={"fullText"} query={query} action={{ Submit: setQuery }} placeHolder="Find ITR or Inspection items"></InputSearch>
          </div>
          <div className={`col-12 h-100 paginationScrollable`}>
            <ArchiveGrid action={{ openDetail }} data={GridDto?.items} query={query} />
          </div>
        </div>}
    </div>
  );
}
export default ArchiveContainer
