import { Component } from 'react';
import PropTypes from 'prop-types';

const inBrowser = typeof navigator !== 'undefined';
const channel = window.BroadcastChannel ? new BroadcastChannel('saipem-hoc4all') : null;

class OnLineOffLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: inBrowser && typeof navigator.onLine === 'boolean' ? navigator.onLine : true
    };
    // bind event handlers
    this.goOnline = this.goOnline.bind(this);
    this.goOffline = this.goOffline.bind(this);
    this.onMessageReceived = this.onMessageReceived.bind(this);
  }

  componentDidMount() {
    window.addEventListener('online', this.goOnline);
    window.addEventListener('offline', this.goOffline);
    if (channel)
      channel.addEventListener('message', this.onMessageReceived);
    else
      navigator.serviceWorker.addEventListener('message', this.onMessageReceived);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.goOnline);
    window.removeEventListener('offline', this.goOffline);
    if (channel)
      channel.removeEventListener('message', this.onMessageReceived);
    else
      navigator.serviceWorker.removeEventListener('message', this.onMessageReceived);
  }

  onMessageReceived(message) {
    if (this.props.onMessageReceived) this.props.onMessageReceived(message.data);
  }

  goOnline() {
    if (!this.state.online) {
      this.callOnChangeHandler(true);
      this.setState({ online: true });
    }
  }

  goOffline() {
    if (this.state.online) {
      this.callOnChangeHandler(false);
      this.setState({ online: false });
    }
  }

  callOnChangeHandler(online) {
    if (this.props.onChange) {
      this.props.onChange(online);
    }
  }
}

OnLineOffLine.propTypes = {
  onChange: PropTypes.func,
  onMessageReceived: PropTypes.func
};
OnLineOffLine.defaultProps = {};

export class Online extends OnLineOffLine {
  render() {
    return this.state.online ? this.props.children : null;
  }
}
Online.propTypes = OnLineOffLine.propTypes;
Online.defaultProps = OnLineOffLine.defaultProps;

export class Offline extends OnLineOffLine {
  render() {
    return !this.state.online ? this.props.children : null;
  }
}
Offline.propTypes = OnLineOffLine.propTypes;
Offline.defaultProps = OnLineOffLine.defaultProps;
