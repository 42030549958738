import '../../Css/ItrList.css';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ActionButtonIcon from '../../Components/GridSystem/ActionButtonIcon';
import PropertyItem from '../../Components/GridSystem/PropertyItem';
import { formatTime } from '../../Hook/Common/TimeAndDate';
import { RootState, rootStore } from '../../Redux/Store/rootStore';
import eye from '../../img/eye.png'
import paperclip from '../../img/paperclip.png'
import { DataModalConfirm, DataModalImgPreview, stateConfirm } from '../../Model/CommonModel';
import { DarkTheme } from '../../App';
import { Attachment, PunchlistModel } from 'Model/PunchlistList';
import { DownloadPunchlistImageItem } from 'Redux/Action/PunchlistList/PunchlistListDownloadAction';
import Attachments from './Attachments';
import { AddUpdatePunchlistModel } from 'Redux/Action/PunchlistList/PunchlistListEditAction';
import ModalImgPreview from './ModalImgPreview';
import { PunchlistDropdownOptions } from 'Model/PunchlistList';
import { GetDiscListModel, GetCategoryListModel, GetPunchListTypeListModel, GetAPListModel, GetInspItemListModel } from 'Redux/Action/PunchlistList/PunchlistListGridAction';
import { GET_ITR_DETAIL, ItrModelDetail } from 'Model/ItrList';

interface Props {
  action: {
    backAction(changed: boolean): any
    setConfirm(data: DataModalConfirm): any
  }
  isOnline: boolean
  buttonHidden?: boolean
  plData?: PunchlistModel
}

const PunchlistDetail: React.FC<Props> = (props) => {


  const itrDetailData = (state: RootState) => state.itrListDetailReducer.dtoEdit
  const itrData = useSelector(itrDetailData);

  const projectCode = localStorage.getItem('projectCode')
  const DetailData = (state: RootState) => state.punchlistListDetailReducer.dtoEdit;
  const data = useSelector(DetailData);


  const plItemData = props.plData ? props.plData : data

  const handleClosePreview = () => {
    setPreviewData(previewDefaultData)
    return () => URL.revokeObjectURL(objectUrl)
  }

  const previewDefaultData = {
    title: '',
    img: '',
    isOpen: false,
    actions: {
      cancel: () => handleClosePreview()
    }
  }


  const [changed, setChanged] = useState<boolean>()
  const [attachment, setAttachment] = useState<Attachment[] | undefined>(plItemData?.attachments[0] === null ? [] : plItemData?.attachments);
  const [newAttachments, setNewAttachments] = useState<File[]>([])
  const [objectUrl, setObjUrl] = useState<string>('')
  const [preview, setPreview] = useState('');
  const [previewData, setPreviewData] = useState<DataModalImgPreview>(previewDefaultData)
  const handleChangeAttachments = (e) => {
    setNewAttachments(e.target.files)
  }
  const [dropdownOptionsState, setDropDownOptionsState] = useState<PunchlistDropdownOptions>({
    disciplineOptions: [{ value: "", text: "" }],
    categoryOptions: [{ value: "", text: "" }],
    actionPartyOptions: [{ value: "", text: "" }],
    inspItemOptions: [{ value: "", text: "" }],
    plTypeOptions: [{ value: "", text: "" }],
  })


  useEffect(() => {
    const promiseDisc = GetDiscListModel()
    const promiseCategory = GetCategoryListModel()
    const plType = GetPunchListTypeListModel()
    const promiseAP = GetAPListModel(Number(projectCode))
    const promiseInsp = GetInspItemListModel(Number(projectCode))

    Promise.all([promiseDisc, promiseCategory, promiseAP, promiseInsp, plType]).then((values) => {
      setDropDownOptionsState({
        disciplineOptions: values[0],
        categoryOptions: values[1],
        actionPartyOptions: values[2],
        inspItemOptions: values[3],
        plTypeOptions: values[4],
      } as PunchlistDropdownOptions)
    })

  }, [])

  useEffect(() => {
    if (changed) {
      props.action.backAction(changed)
    }
  }, [changed])

  const handleSubmit = async (operation: string, type?: string) => {

    const dataToSave = {
      idUniqueITR: plItemData?.idUniqueITR?.toString() || '',
      idInspItem: dropdownOptionsState.inspItemOptions?.filter((x) => x.code === plItemData?.inspItemCode)[0]?.value || '',
      idCategory: dropdownOptionsState.categoryOptions?.filter((x) => x.code === plItemData?.categoryCode)[0]?.value || '',
      idDisciplina: dropdownOptionsState.disciplineOptions?.filter((x) => x.code === plItemData?.disciplineCode)[0]?.value || '',
      idPunchListType: dropdownOptionsState.plTypeOptions?.filter((x) => x.text === plItemData?.plTypeCode)[0]?.value || '',
      idActionParty: dropdownOptionsState.actionPartyOptions?.filter((x) => x.code === plItemData?.actionPartyCode)[0]?.value || '',
      description: plItemData?.plDescription ?? '',
      attachment: newAttachments,
      idUniquePL: plItemData?.idUniquePL,

    }
    await AddUpdatePunchlistModel(dataToSave, operation, type).then((result) => {
      if (!result?.warning) {
        if (!props.plData) {
          // Filter punchList array based on idUniquePL
          const filteredPunchList = itrData?.punchList.filter(x => x.idUniquePL === data?.idUniquePL);

          // Assign values from dataToSave to the first element of filteredPunchList
          if (filteredPunchList && filteredPunchList.length > 0) {
            const firstItem = filteredPunchList[0];
            switch (type) {
              case "Clear":
                firstItem.statusPL = "Cleared"
                break;
              case "Accept":
                firstItem.statusPL = "Accepted"
                break;
              default:
                firstItem.statusPL = "Rejected"
                break;
            }
          }
          rootStore.dispatch({ type: GET_ITR_DETAIL, payload: { dtoEdit: itrData, resultDto: null } as ItrModelDetail });
        }
        localStorage.setItem("isEdited", `true`);
      }

    })
  }

  useEffect(() => {
    if (objectUrl !== '') {
      setPreview(objectUrl)
    }
  }, [objectUrl])

  useEffect(() => {
    setPreviewData({
      ...previewData,
      img: preview,
    })
  }, [preview])

  const handleOpenPreview = (index) => {
    if (newAttachments.length > 0) {
      setObjUrl(URL.createObjectURL(newAttachments[index]))
      setPreviewData({
        title: newAttachments[index]?.name as string,
        img: '',
        isOpen: true,
        actions: {
          cancel: () => handleClosePreview()
        }
      })
    }
  }

  const InvocheDownload = async (id: number, idUniquePL: number) => {
    const result = await DownloadPunchlistImageItem(id, idUniquePL);
    if (result !== undefined) {
      const url = window.URL.createObjectURL(result.file);
      const a = document.createElement("a");
      a.href = url;
      a.download = result.fileName;
      a.click();
      rootStore.dispatch({ type: "PUSH_PL_DOWNLOAD", payload: [id] });
    }
  };

  const darkTheme = useContext(DarkTheme)

  const confirmChangeStatus = (id: number, status: string) => {
    switch (status) {
      case "Raised":
        props.action.setConfirm({
          title: "Clear Punchlist",
          buttonText: "Cleared",
          secondaryButtonText: "Back",
          hideCancel: true,
          message: "Would you like to clear this punchlist?",
          isOpen: true,
          actions: {
            cancel: () => { props.action.setConfirm(stateConfirm) },
            confirm: async () => {
              await handleSubmit("Status", "Clear").then(x => {
                props.action.setConfirm(stateConfirm)
                setChanged(true)
              });
            },
            secondaryConfirm: () => { props.action.setConfirm(stateConfirm) }
          },
        })
        return;

      case "Rejected":
        props.action.setConfirm({
          title: "Clear Punchlist",
          buttonText: "Cleared",
          secondaryButtonText: "Back",
          hideCancel: true,
          message: "Would you like to clear this punchlist?",
          isOpen: true,
          actions: {
            cancel: () => { props.action.setConfirm(stateConfirm) },
            confirm: async () => {
              await handleSubmit("Status", "Clear").then(x => {
                props.action.setConfirm(stateConfirm)
                setChanged(true)
              });
            },
            secondaryConfirm: () => { props.action.setConfirm(stateConfirm) }
          },
        })
        return;

      default:
        props.action.setConfirm({
          title: "Punchlist approval",
          buttonText: "Accept",
          secondaryButtonText: "Reject",
          hideCancel: true,
          message: "Select an action on this Punchlist",
          isOpen: true,
          actions: {
            cancel: () => { props.action.setConfirm(stateConfirm) },
            confirm: async () => {
              await handleSubmit("Status", "Accept").then(x => {
                props.action.setConfirm(stateConfirm)
                setChanged(true)
              });
            },
            secondaryConfirm: async () => {
              await handleSubmit("Reject").then(x => {
                props.action.setConfirm(stateConfirm)
                setChanged(true)
              });
            },
          },
        })
        return;
    }
  }

  return (

    <div className="w-100">
      <ModalImgPreview data={previewData} />
      <div className="col-12 pt-2">
        <div className="d-flex col-12 px-0 justify-content-between align-items-center pb-2">
          <h4 className="mb-0">Details</h4>
          {props.buttonHidden !== true && plItemData?.statusPL !== "New" && plItemData?.statusPL !== "Accepted" ?
            <ActionButtonIcon buttonClassName="icon-md" imageClassName={darkTheme ? "brightness" : ""} action={() => plItemData?.idUniquePL && confirmChangeStatus(plItemData?.idUniquePL, plItemData.statusPL)} icon={eye} />
            : null}
        </div>
        <div className="itr-item row mx-0 mb-3">
          <div className="col-12 row mx-0 py-0 py-lg-2">
            <PropertyItem label={"Unique ITR Id"} property={"Unique ITR Id"} index={0} value={plItemData?.idUniqueITR} />
            <PropertyItem label={"Unique PL"} property={"Unique PL"} index={0} value={plItemData?.idUniquePL} />
            <PropertyItem label={"Inspection item"} property={"Inspection item"} index={0} value={plItemData?.inspItemCode} />
          </div>
          <div className="col-12 row mx-0 py-0 py-lg-2">
            <PropertyItem label={"System"} property={"System"} index={0} value={plItemData?.systemCode} />
            <PropertyItem label={"Sub System"} property={"Sub System"} index={0} value={plItemData?.subsystemCode} />
            <PropertyItem label={"Category"} property={"Category"} index={0} value={plItemData?.categoryCode} />
          </div>
          <div className="col-12 row mx-0 py-0 py-lg-2">
            <PropertyItem label={"Discipline"} property={"Discipline"} index={0} value={plItemData?.disciplineCode} />
            <PropertyItem label={"Action party"} property={"Action party"} index={0} value={plItemData?.actionPartyCode} />
            <PropertyItem label={"Punchlist type"} property={"Punchlist type"} index={0} value={plItemData?.plTypeCode} />
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex col-12 px-0 justify-content-between align-items-center pb-2">
          <h4 className="">Description</h4>
          {plItemData?.statusPL !== "Accepted" &&
            <div className="d-flex align-items-center">
              <label className={`btn btn-icon buttonAction trasparent-bg mx-1 icon-lg mb-0 ${!navigator.onLine ? "disabled" : ""}`} htmlFor={`uploadPlAttachment`}>
                <img alt="icon" className={darkTheme ? "brightness" : ""} src={paperclip}></img>
                <input accept="image/*" className='d-none' multiple={true} id={`uploadPlAttachment`} type="file" onClick={() => setNewAttachments([])} onChange={handleChangeAttachments} />
              </label>
            </div>
          }
        </div>
        <div className="itr-item row mx-0 mb-3">
          <div className="col-12 row mx-0 py-0 py-lg-2">
            <p className={`${darkTheme ? "bright" : ""}`}>
              {plItemData?.plDescription}
            </p>
          </div>
        </div>
        {
          (newAttachments.length > 0 || (attachment !== undefined && attachment.length > 0)) &&
          <div className="create-pl-item-container row mb-3">
            <div className="d-flex create-pl-item py-lg-2 col-12 justify-content-between align-items-center">
              {
                attachment &&
                attachment.map((item, index) => {
                  return (
                    <Attachments idPL={data?.idUniquePL} id={item?.idAttachment} key={index} isNew={false} index={index} title={item?.fileName} handlePreview={handleOpenPreview} handleDownload={InvocheDownload} />
                  )
                })
              }
              {
                newAttachments &&
                [...newAttachments].map((item, index) => {
                  return (
                    <Attachments key={index} isNew={true} index={index} title={item?.name} handlePreview={handleOpenPreview} handleDownload={() => console.log()} />
                  )
                })
              }
            </div>
          </div>
        }
      </div>
      <div className="col-12">
        <div className="d-flex col-12 px-0 justify-content-between align-items-center pb-2">
          <h4 className="">Status</h4>
        </div>
        <div className="itr-item row mx-0 mb-3">
          <div className="col-12 row mx-0 py-0 py-lg-2">
            <PropertyItem valueClassName={""} labelClassName={""} label={"Raised by"} property={"Raised by"} index={0} value={plItemData?.raisedBy} />
            <PropertyItem valueClassName={""} labelClassName={""} label={"Date"} property={"Date"} index={0} value={formatTime(plItemData?.raisedDate)} />
            <PropertyItem valueClassName={`status ${plItemData?.statusPL}`} isBlue={true} label={"Current status"} property={"Current status"} index={0} value={plItemData?.statusPL} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default PunchlistDetail



