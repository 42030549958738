import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './Css/bootstrap.min.css'
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { rootStore } from './Redux/Store/rootStore';
import * as Config from './auth/config';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


ReactDOM.render(
  <Provider store={rootStore}>
    <App />
  </Provider>,
  rootElement);

registerServiceWorker(Config.swFile);
//unregister()

