import React, { SetStateAction, useContext, useEffect, useState } from "react";
import '../../Css/Filters.css'
import '../../Css/Index.css'
import { ItrModelQuery } from "../../Model/ItrList";
import { FilterValueDto, QueryObject } from '../../Model/CommonModel';
import { GetFilterColumnItrModel } from "../../Redux/Action/ItrList/ItrListGridAction";
import { stringIsNullOrEmpty } from "../../Hook/Common/Utility";
import Select from "react-select";
import { DarkTheme } from "../../App";

interface Props {
    action: {
        applyFilters(query: SetStateAction<QueryObject>, forceClassicPagination?: boolean): any
    }
    query: QueryObject
    initialState: ItrModelQuery
}

const SelectProjectMenu: React.FC<Props> = (props) => {

    const [filtersResource, setFiltersResource] = useState<FilterValueDto[]>([])
    const [projectSelected, setProjectSelected] = useState<string>("")
    const [projectId, setProjectId] = useState<string>("")
    
    const darkTheme = useContext(DarkTheme)

    useEffect(() => {
        GetFilterColumnItrModel("projectCode", "", props.initialState).then(x => {
            if (x) {
                const uniqueArray = x?.filter((item, index) => {
                    return x.findIndex(obj => obj.value === item.value) === index;
                  });
                setFiltersResource(uniqueArray)
            }
        })
    }, [])

    const onChangeProject = (e: any) => {
        if (e && e['key']) {
            const value = e['value'] as string;
            const key = e['key'] as string;
            setProjectSelected(value)
            setProjectId(key)
        }
    }

    const submitProject = () => {
        if (!stringIsNullOrEmpty(projectId)) {
            const copy = { ...props.query } as ItrModelQuery
            copy.projectCode = [projectId]
            props.action.applyFilters(copy, true)
            localStorage.setItem("projectCode", projectId)
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-center col-12 col-lg-8">
            <div className={`row mx-0`}>
                <div className={`col-12  py-3 filterMenu-container ${darkTheme ? "dark": ""}`}>
                        <div className="col-12 d-flex align-items-center">
                            <h3 className="text-white mb-0">Select a project</h3>
                        </div>
                        <div className="col-12 px-0 row mx-0 d-flex align-items-center py-3">
                            <div className="col-12 d-flex">
                                <div className="form-group w-100">
                                    <label className="labelForm w-100">
                                        <span className="text-uppercase text-white">Project Code</span>
                                        <div className="d-flex">
                                            <div className="w-100">
                                                <Select
                                                    options={filtersResource.length > 0 
                                                        ? filtersResource
                                                            ?.filter(x => !stringIsNullOrEmpty(x.value) && !stringIsNullOrEmpty(x.text))
                                                            .map(x => { return { key: x.value, value: x.text,code:x.code, dto: x.dto } }) 
                                                        : []}
                                                    value={filtersResource.length > 0 
                                                        ? filtersResource
                                                            .map(x => { return { key: x.value, value: x.text,code:x.code, dto: x.dto } })
                                                            ?.filter(x => projectSelected === x.value)
                                                        : []}
                                                    onChange={(e) => onChangeProject(e)}
                                                    getOptionLabel={(option) => option.value}
                                                    getOptionValue={(option) => option.key?.toString() ?? ""} 
                                                    formatOptionLabel={function (data) {
                                                        return <span className={`optionText`} dangerouslySetInnerHTML={{ __html: data.value }} />;
                                                    }}/>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-end align-items-center pt-3 pb-4">
                            <button className="btn btn-xl primary-bg" type="button" onClick={() => submitProject()}>
                                Confirm
                            </button>
                        </div>
                </div>
            </div>
        </div>
    )
}
export default SelectProjectMenu