import '../../Css/Index.css';
import '../../Css/ItrList.css';
import React, { useContext, useState } from 'react';
import { ClearLocalForge } from '../../Business/Store/CommonStore';
import ButtonActionIconTextHoriz from '../../Components/Various/ButtonActionIconTextHoriz';
import profile from '../../img/profile.png'
import notifications from '../../img/notifications.png'
import settings from '../../img/settings.png'
import reset from '../../img/reset.png'
import logout from '../../img/logout.png'
import FiltersBooleanDoubleRadio from '../../Components/FormAndFilters/FiltersAutogen/FiltersBooleanDoubleRadio';
import { DarkTheme } from '../../App';

interface Props {
  logout(): any
  setDarkTheme(val: boolean): any
  // darkTheme: boolean
}

const ProfileContainer: React.FC<Props> = (props) => {

  const [isSettingsOpened, setIsSettingsOpened] = useState<boolean>(false)

  const darkTheme = useContext(DarkTheme)

  const clearAndLogout = () => {
    ClearLocalForge(false);
    localStorage.removeItem("projectCode");
    props.logout()
  }

  const setDarkTheme = (prop: string, val: boolean) => {
    const root = document.getElementById("root")

    if (val) {
      localStorage.setItem("theme", "dark")
      if (root) {
        root.setAttribute("class", "darkTheme")
      }
      props.setDarkTheme(true)
    } else {
      localStorage.setItem("theme", "classic")
      if (root) {
        root.setAttribute("class", "")
      }
      props.setDarkTheme(false)
    }
  }

  return (
    <div className={`col-12 px-0 mt-3 row mx-0 relative h-100 pageContainer ${darkTheme ? "dark" : ""}`}>
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between col-12">
          <h2 className="mb-0">Profile</h2>
        </div>
        <div className="col-12 mt-3">
          <ButtonActionIconTextHoriz label="Profile Information" icon={profile} disabled={true} />
          <ButtonActionIconTextHoriz label="Notifications" icon={notifications} disabled={true} />
          <ButtonActionIconTextHoriz label="Settings" icon={settings} disabled={false} action={() => setIsSettingsOpened(!isSettingsOpened)} />
          {isSettingsOpened ?
            <div className="col-12 d-flex flex-column pb-4 py-3">
              <h4 className="mb-1">Theme</h4>
              <div>
                <FiltersBooleanDoubleRadio
                  action={{ onChange: setDarkTheme }}
                  data={{ property: "theme", value: darkTheme }}
                  settings={{
                    overrideLabels: ["Dark", "Classic"],
                    verticalLayout: true,
                    containerClass: "pl-0",
                    inputClass: !darkTheme ? "invert" : "",
                    labelClass: !darkTheme ? "text-dark bold" : ""
                  }} />
              </div>
            </div> : null}

          <ButtonActionIconTextHoriz label="Reset Offline Database" icon={reset} disabled={!navigator.onLine} action={() => ClearLocalForge(true)} />
          <ButtonActionIconTextHoriz label="Logout" icon={logout} disabled={false} action={clearAndLogout} />
        </div>
      </div>
    </div>
  );
}
export default ProfileContainer
