import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { PunchlistModel, PL_LOCAL, PL_FILTERS_LOCAL, PL_DISCS_LOCAL, PL_CATEGORIES_LOCAL, PL_AP_LOCAL, PL_INSPITEM_LOCAL, PL_PUNCHLIST_TYPE_LOCAL } from "../../../Model/PunchlistList"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StorePunchlist = async (punchlist: PunchlistModel, fileName: string) => {

  const punchlistList: PunchlistModel[] = await localforage.getItem(PL_LOCAL) ?? [];
  punchlist.fileName = fileName;
  punchlist.on = formatDateWithTime(new Date())?.toString() ?? "";
  punchlistList.push(punchlist);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(punchlistList.map(item =>
    [item[key], item])).values()];
  localforage.setItem(PL_LOCAL, arrayUniqueByKey);

}

export const StorePunchlistFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    const filterList: FilterValueDto[] = await localforage.getItem(PL_FILTERS_LOCAL + column) ?? [];
    const union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(PL_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}

export const StorePunchlistDiscList = async (filters: FilterValueDto[]) => {
  if (filters && filters.length) {
    const filterList: FilterValueDto[] = await localforage.getItem(PL_DISCS_LOCAL) ?? [];
    const union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(PL_DISCS_LOCAL, arrayUniqueByKey);
  }
}

export const StorePunchlistCategoriesList = async (filters: FilterValueDto[]) => {
  if (filters && filters.length) {
    const filterList: FilterValueDto[] = await localforage.getItem(PL_CATEGORIES_LOCAL) ?? [];
    const union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(PL_CATEGORIES_LOCAL, arrayUniqueByKey);
  }
}

export const StorePunchlistAPList = async (filters: FilterValueDto[]) => {
  if (filters && filters.length) {
    const filterList: FilterValueDto[] = await localforage.getItem(PL_AP_LOCAL) ?? [];
    const union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(PL_AP_LOCAL, arrayUniqueByKey);
  }
}

export const StorePunchlistInspItemList = async (filters: FilterValueDto[]) => {
  if (filters && filters.length) {
    const filterList: FilterValueDto[] = await localforage.getItem(PL_INSPITEM_LOCAL) ?? [];
    const union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(PL_INSPITEM_LOCAL, arrayUniqueByKey);
  }
}

export const StorePunchlistPunchListTypeList = async (filters: FilterValueDto[]) => {
  if (filters && filters.length) {
    const filterList: FilterValueDto[] = await localforage.getItem(PL_PUNCHLIST_TYPE_LOCAL) ?? [];
    const union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(PL_PUNCHLIST_TYPE_LOCAL, arrayUniqueByKey);
  }
}







