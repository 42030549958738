import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { FileResult, ReturnFile } from '../../../Model/CommonModel';
import { PunchlistListApi } from "../../../Business/PunchlistListBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from "../NotificationAction";
import { rootStore } from "../../Store/rootStore";
import { NotifyType } from "../../Reducer/NotificationReducer";


export async function DownloadPunchlistImageItem(id: number, idUniquePL: number) {

  setLoader("ADD", "DownloadPunchlistImageItem");
  const api = new PunchlistListApi();

  const res = await ApiCallWithErrorHandling<Promise<ReturnFile>>(() =>
    api.PunchlistImageDownload(id, idUniquePL)
  );
  if (res?.FileName === null) {
    rootStore.dispatch(setNotification({ message: "File not found", notifyType: NotifyType.warning }));
    setLoader("REMOVE", "DownloadPunchlistImageItem");
  } else {
    const fileNameBase = res?.FileName?.split(";")[1] ?? "";
    const index = fileNameBase?.indexOf('"') + 1;
    const lastIndex = fileNameBase?.indexOf('"', index);
    const fileName = fileNameBase?.substring(index, lastIndex);
    const result = res?.File.then((x) => {
      setLoader("REMOVE", "DownloadPunchlistImageItem");
      return { file: x, fileName: fileName } as FileResult;
    });
    setLoader("REMOVE", "DownloadPunchlistImageItem");
    return result;
  }
}
