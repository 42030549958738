import React, { FormEvent, useContext, useEffect, useState } from "react";
import { DarkTheme } from "../../App";
import '../../Css/Index.css'
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { QueryObject } from '../../Model/CommonModel';

interface Props {
    labelText?: string
    placeHolder?: string
    propertyName: string
    action: {
        Submit(obj: QueryObject, forceClassic?: boolean): any
    }
    query: QueryObject
}

const FiltersFullTextInputSearch: React.FC<Props> = (props) => {

    const darkTheme = useContext(DarkTheme)

    const [searchText, setSearchText] = useState<string>("")

    useEffect(() => {
        if (!stringIsNullOrEmpty(props.query[props.propertyName] as string)) {
            setSearchText(props.query[props.propertyName] as string)
        } else {
            setSearchText("")
        }
    }, [props.query])

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const copy = { ...props.query } as QueryObject
        copy[props.propertyName] = searchText;
        copy.page = 1
        props.action.Submit(copy, true)
    }

    const resetFullText = () => {
        const copy = { ...props.query } as QueryObject
        copy[props.propertyName] = "";
        copy.page = 1
        props.action.Submit(copy, true)
    }
    
    return (
        <div>
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="input-group mb-1">
                    <input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="text" className={`form-control inputSearch ${darkTheme ? "dark" : ""}`} placeholder={props.placeHolder ?? ""} />
                    <div className="input-group-append relative">
                            <button className={`btn btn-icon icon-sm trasparent-bg h-100 ${!stringIsNullOrEmpty(props.query[props.propertyName] as string) ? "" : "d-none"}`} style={{ position: 'absolute', left: '-35px' }} type="button" onClick={() => resetFullText()}>
                                <img alt="reset" src={require('../../svg/close_w.svg').default}></img>
                            </button>
                        <button className="btn inputSearch primary-bg bold" type="submit">Search</button>
                    </div>
                </div>
            </form>
            {props.labelText && <label>{props.labelText}</label>}
        </div>
    )
}
export default FiltersFullTextInputSearch