import '../../Css/ItrList.css';
import React, { useContext } from 'react';
import PropertyItem from '../../Components/GridSystem/PropertyItem';
import { arrayIsNullOrEmpty, setCssClass } from '../../Hook/Common/Utility';
import { ItrModel, ItrModelQuery } from '../../Model/ItrList';
import { DarkTheme } from '../../App';

interface Props {
  action: {
    openDetail(item: ItrModel): any;
  }
  data: ItrModel[] | undefined
  query: ItrModelQuery
}

const ArchiveGrid: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  return (
    <div className="w-100 pt-4 row mx-0">
      <div className="col-12 px-0">
        {/* LIST ITEM */}
        {!arrayIsNullOrEmpty(props.data) ? props.data?.map((item, i) => (
          <div className={`itr-item ${darkTheme ? "dark" : ""}`} key={`${item.id}-${i}`} id={`itemList${item.id}`}>
            <div className="col clickable row mx-0"
              onMouseEnter={() => setCssClass(`itemList${item.id}`, `itr-item ${darkTheme ? "dark hover" : "hover"}`)}
              onMouseLeave={() => setCssClass(`itemList${item.id}`, `itr-item ${darkTheme ? "dark" : ""}`)}
              onClick={() => props.action.openDetail(item)}>
              <PropertyItem label={"ITR code"} property={"ITR code"} index={i} value={item.itrCode} />
              <PropertyItem label={"Insp. item"} property={"Inspection item"} index={i} value={item.itemCode} />
              <PropertyItem label={"File name"} property={"FileName"} index={i} value={item.fileName} />
              <PropertyItem label={"On"} property={"Date"} index={i} value={item.on} />
            </div>
          </div>
        ))
          :
          <div className="w-100 my-2">
            <h5>The search did not return any results</h5>
          </div>
        }
      </div>
    </div>
  );
}
export default ArchiveGrid
