import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_PL_DETAIL, PunchlistModelDetail, ApprovedModel, PL_LOCAL, PunchlistModel, PunchlistAddUpdateModel } from '../../../Model/PunchlistList';
import { PunchlistListApi } from "../../../Business/PunchlistListBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import localforage from 'localforage';

export const ChangeStatusPunchlistModel = async (id: number, file?: Blob) => {
    setLoader("ADD", "ChangeStatusPunchlistModel");
    const model = { id: id, file: file } as ApprovedModel
    const api = new PunchlistListApi();
    const result = await ApiCallWithErrorHandling(() => api.PunchlistItemChangeStatus(model));
    if (result && !result.warning) {
        rootStore.dispatch({ type: GET_PL_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as PunchlistModelDetail });

        const list = await localforage.getItem(PL_LOCAL) as PunchlistModel[]
        const listFiltered = list?.filter(x => x.idUniquePL !== id)

        const arr = [] as number[]
        listFiltered?.map(x => {
            arr.push(x.idUniquePL)
        })
        rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
        localforage.setItem(PL_LOCAL, listFiltered)
    }

    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    setLoader("REMOVE", "ChangeStatusPunchlistModel");
    return
}

export const ChangeStatusPunchlistRejected = async (id: number, file?: Blob) => {
    setLoader("ADD", "ChangeStatusPunchlistRejected");
    const model = { id: id, file: file } as ApprovedModel
    const api = new PunchlistListApi();
    const result = await ApiCallWithErrorHandling(() => api.PunchlistItemChangeStatusRejected(model));
    if (result && !result.warning) {
        rootStore.dispatch({ type: GET_PL_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as PunchlistModelDetail });

        const list = await localforage.getItem(PL_LOCAL) as PunchlistModel[]
        const listFiltered = list?.filter(x => x.idUniquePL !== id)

        const arr = [] as number[]
        listFiltered?.map(x => {
            arr.push(x.idUniquePL)
        })
        rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
        localforage.setItem(PL_LOCAL, listFiltered)
    }

    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    setLoader("REMOVE", "ChangeStatusPunchlistRejected");
    return
}

export const RejectPunchlistModel = async (id: number) => {
    setLoader("ADD", "RejectPunchlistModel");
    const api = new PunchlistListApi();
    const result = await ApiCallWithErrorHandling(() => api.PunchlistItemReject(id));
    if (result && !result.warning) {
        rootStore.dispatch({ type: GET_PL_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as PunchlistModelDetail });
    }
    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    setLoader("REMOVE", "RejectPunchlistModel");
    return
}

export const AddUpdatePunchlistModel = async (data: PunchlistAddUpdateModel,operation:string,type?:string) => {
    setLoader("ADD", "AddUpdatePunchlistModel");
    const api = new PunchlistListApi();
    const result = await ApiCallWithErrorHandling(() => api.PunchlistAddUpdate(data,operation,type));
    if (result && !result.warning) {
        rootStore.dispatch({ type: GET_PL_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as PunchlistModelDetail });
    }
    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    setLoader("REMOVE", "AddUpdatePunchlistModel");
    return result
}


