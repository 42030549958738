import React from 'react';
import PropertyItem from '../../Components/GridSystem/PropertyItem';
import { formatTime } from '../Common/TimeAndDate';


export function SelectGridPropertyItem(value: any, propertyName: string, propertyText: string, type: number, index: number, valueClassName?: string, labelClassName?: string) {
    switch (type) {
        case 3:
            return (
                <PropertyItem
                    key={`${propertyName}-${index}`}
                    valueClassName={`${propertyName === "statusTxt" ? "status " + value : ""} ${valueClassName}`}
                    labelClassName={labelClassName}
                    label={propertyText}
                    property={propertyName}
                    index={index}
                    value={formatTime(value) ?? ""} />
            )

        default:
            return (
                <PropertyItem
                    key={`${propertyName}-${index}`}
                    valueClassName={`${propertyName === "statusTxt" ? "status " + value : ""} ${valueClassName}`}
                    labelClassName={labelClassName}
                    label={propertyText}
                    property={propertyName}
                    index={index}
                    value={value}
                    isBlue={propertyName === "statusPL" ? true : false}
                />

            )
    }
}