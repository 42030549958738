// tslint:disable
import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import * as Config from '../../auth/config';
import { getTokenAccessAsync } from '../../auth/msalProvider';

export const basePath = Config.environment[window.location.host].apiUrl;

// console.log('basePath',basePath)

export const GetHeaderAsync = async ( bypassContentType?: boolean,contentType?: string, isMultipart?:boolean) => {

  const headers = new Headers();
  const token = await getTokenAccessAsync();
  headers.append("Authorization", token);

  if(isMultipart){
    headers.append("Content-type", "multipart/form-data")
  }

  if (bypassContentType !== true) {
    if (contentType) {
      headers.append("Content-Type", contentType);
    } else {
      headers.append("Content-Type", "application/json");
    }
  }

  return headers;
};

export interface ConfigurationParameters {
  apiKey?: string | ((name: string) => string);
  username?: string;
  password?: string;
  accessToken?: string | ((name: string, scopes?: string[]) => string);
  basePath?: string;
}


/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};
