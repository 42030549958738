import { useEffect, useState } from "react";
import { QueryObject } from "../../Model/CommonModel";
import setLoader from "../../Redux/Action/LoaderAction";
import { arrayIsNullOrEmpty } from '../Common/Utility';

export function useResourceTableCrud(initialState: QueryObject, functionForRefillGrid: Function, infiniteScroll?: boolean, functionForAddGridResults?: Function, dataToConcat?: any[]) {

	const [query, setQuery] = useState<QueryObject>(initialState);

	//RESET CAMBIO DEVICE LAYOUT
	useEffect(() => {
		if (infiniteScroll !== undefined) {
			// console.log("INFINITESCROLL IS CHANGED------------", infiniteScroll)
			if (query) {
				const copy = { ...query };
				if (copy.page !== undefined) {
					copy.page = 1;
				}
				changeQuery(copy, true)
			}
		}

	}, [infiniteScroll])

	//PAG + 1
	const next = () => {
		const copy = { ...query };
		if (copy.page !== undefined) {
			copy.page = copy.page + 1;
		}
		changeQuery(copy);
	};

	//PAG - 1
	const back = () => {
		const copy = { ...query };
		if (copy.page !== undefined) {
			copy.page = copy.page - 1;
		}
		changeQuery(copy);
	};

	//MOTORE DI REFRESH
	const changeQuery = (obj: QueryObject, forceClassicRefill?: boolean) => {
		// console.log("QUERY IS CHANGED------------", obj)
		if (infiniteScroll === true && !arrayIsNullOrEmpty(dataToConcat) && forceClassicRefill !== true) {
			if (functionForAddGridResults)
				functionForAddGridResults(obj, dataToConcat).then(x => {
					setLoader("REMOVE", functionForAddGridResults.name)
				});
		} else {
			functionForRefillGrid(obj).then(x => {
				setLoader("REMOVE", functionForRefillGrid.name)
			});
		}
		setQuery(obj)
	}

	return { query, setQuery: changeQuery, next, back };
}
