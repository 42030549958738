import React, { useContext } from "react";
import { DarkTheme } from "../../App";
import '../../Css/Filters.css'
import '../../Css/Index.css'
import '../../Css/ItrList.css';


interface Props {
    action?(): any
    icon: string
    label: string
    disabled?: boolean
    active?: boolean
}

const ButtonActionIconTextHoriz: React.FC<Props> = (props) => {

    const darkTheme = useContext(DarkTheme)

    return (
        <div className="row mx-0">
            <button className="btn btn-link-icon d-flex align-items-center justify-content-center flex-row text-nowrap" disabled={props.disabled} onClick={() => props.action && props.action()}>
                <div>
                    <img className={darkTheme ? "invert" : ""} alt={props.label} src={props.icon}></img>
                </div>
                <span>{props.label}</span>
            </button>
        </div>
    )
}
export default ButtonActionIconTextHoriz