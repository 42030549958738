import { ADD_PL_GRID, GET_PL_FILTERS, GET_PL_GRID, PunchlistModelGrid } from 'Model/PunchlistList';

const initState: PunchlistModelGrid = {
    gridResult: null,
    filter: null,
}

export const PunchlistListGridReducer = (state = initState, action: { type: string, payload: PunchlistModelGrid }) => {
    switch (action.type) {
        case GET_PL_GRID:
        case ADD_PL_GRID:
            {
                return { ...state, gridResult: action.payload.gridResult }
            }
        case GET_PL_FILTERS:
            return { ...state, filter: action.payload.filter }
        default:
            return state;
    }
}