import React from 'react'
import { useSelector } from 'react-redux';
import '../Css/Index.css'
import { RootState } from '../Redux/Store/rootStore';
interface Props {
    show?: boolean | null
    isFullScreen?: boolean
}

const Loader: React.FC<Props> = (props) => {

    Loader.defaultProps = {
        isFullScreen: true
    }

    const getBoolLoader = ((state: RootState) => state)
    const getLoader = useSelector(getBoolLoader).loaderReducer.show;
    const show = props.show === null || props.show === undefined ? getLoader : props.show;
    return (
        !show ? <></> :
            <div className={`${"loaderContainer"} justify-content-center align-items-center getLoader true`}>
                <img className="loaderContainer" alt="loader" src={require('../svg/loader.svg').default} />
            </div>
    )
}
export default Loader