import '../../Css/ItrList.css';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ActionButtonIcon from '../../Components/GridSystem/ActionButtonIcon';
import PropertyItem from '../../Components/GridSystem/PropertyItem';
import { RootState, rootStore } from '../../Redux/Store/rootStore';
import paperclip from '../../img/paperclip.png'
import left from '../../img/left-chevron.png'
import { DarkTheme } from '../../App';
import SelectComponent from 'Components/FormAndFilters/SelectComponent';
import { Button } from 'react-bootstrap';
import ModalImgPreview from './ModalImgPreview';
import { DataModalImgPreview } from 'Model/CommonModel';
import ModalConfirm from 'Components/Various/ModalConfirm';
import { AddUpdatePunchlistModel } from 'Redux/Action/PunchlistList/PunchlistListEditAction';
import Attachments from './Attachments';
import { PunchlistDropdownOptions } from 'Model/PunchlistList';
import { GetDiscListModel, GetCategoryListModel, GetPunchListTypeListModel, GetAPListModel, GetInspItemListModel } from 'Redux/Action/PunchlistList/PunchlistListGridAction';
import Loader from 'Components/Loader';
import setLoader from 'Redux/Action/LoaderAction';




interface Props {
    action: {
        backAction(changed: boolean): any
        addPlAction(id: number,isClosedSelected?:boolean): any
    }
    isOnline: boolean
    isAssociated: boolean
}

const CreatePunchlist: React.FC<Props> = (props) => {
    const projectCode = localStorage.getItem('projectCode')
    const DetailData = (state: RootState) => state.itrListDetailReducer.dtoEdit;
    const data = useSelector(DetailData);
    // const DropdownData = (state: RootState) => state.punchlistDropdownOptionsReducer.dropdownOptions;
    // const dropdownOptions = useSelector(DropdownData)

    const handleClosePreview = () => {
        setPreviewData(previewDefaultData)
        return () => URL.revokeObjectURL(objectUrl)
    }

    const previewDefaultData = {
        title: '',
        img: '',
        isOpen: false,
        actions: {
            cancel: () => handleClosePreview()
        }
    }

    const plCreatedData = {
        title: "Punchlist added successfully",
        buttonText: "View",
        secondaryButtonText: "Close",
        hideCancel: true,
        message: "Punchlist item was added successfully",
        isOpen: true,
        actions: {
            cancel: () => { setIsViewCreatedOpen(false) },
            confirm: () => {
                setIsViewCreatedOpen(false)
                props.action.backAction(true)
                props.action.addPlAction(data?.id as number);
            },
            secondaryConfirm: async () => {
                setIsViewCreatedOpen(false)
                props.action.backAction(true)
                props.action.addPlAction(data?.id as number,true);
            }
        },
    }

    const [changed, setChanged] = useState<boolean>(false);
    const [selectedDiscipline, setSelectedDiscipline] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedActionParty, setSelectedActionParty] = useState('');
    const [selectedPunchlistType, setSelectedPunchlistType] = useState('');
    const [newInspectionItem, setNewInspectionItem] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [preview, setPreview] = useState('');
    const [previewData, setPreviewData] = useState<DataModalImgPreview>(previewDefaultData)
    const [isViewCreatedOpen, setIsViewCreatedOpen] = useState<boolean>(false)
    const [newAttachments, setNewAttachments] = useState<File[]>([])
    const [objectUrl, setObjUrl] = useState<string>('')
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)
    const [dropdownOptionsState, setDropDownOptionsState] = useState<PunchlistDropdownOptions>({
        disciplineOptions: [{ value: "", text: "" }],
        categoryOptions: [{ value: "", text: "" }],
        actionPartyOptions: [{ value: "", text: "" }],
        inspItemOptions: [{ value: "", text: "" }],
        plTypeOptions: [{ value: "", text: "" }],
    })


    useEffect(() => {
        const promiseDisc = GetDiscListModel()
        const promiseCategory = GetCategoryListModel()
        const plType = GetPunchListTypeListModel()
        const promiseAP = GetAPListModel(Number(projectCode))
        const promiseInsp = GetInspItemListModel(Number(projectCode))

        Promise.all([promiseDisc, promiseCategory, promiseAP, promiseInsp, plType]).then((values) => {
            setDropDownOptionsState({
                disciplineOptions: values[0],
                categoryOptions: values[1],
                actionPartyOptions: values[2],
                inspItemOptions: values[3],
                plTypeOptions: values[4],
            } as PunchlistDropdownOptions)
        })

    }, [])

    useEffect(() => {
        if (props.isAssociated) {
            if (newDescription.length > 0) {
                setSubmitDisabled(false)
            }
        } else {
            if (newDescription.length > 0 && newInspectionItem.length > 0) {
                setSubmitDisabled(false)
            }
        }
    }, [newDescription, newInspectionItem])

    const handleOpenPreview = (index) => {
        if (newAttachments.length > 0) {
            setObjUrl(URL.createObjectURL(newAttachments[index]))
            setPreviewData({
                title: newAttachments[index]?.name as string,
                img: '',
                isOpen: true,
                actions: {
                    cancel: () => handleClosePreview()
                }
            })
        }
    }

    const handleChangeAttachments = (e) => {
        setNewAttachments(e.target.files)
    }

    useEffect(() => {
        if (objectUrl !== '') {
            setPreview(objectUrl)
        }
    }, [objectUrl])

    useEffect(() => {
        setPreviewData({
            ...previewData,
            img: preview,
        })
    }, [preview])

    const handleSubmit = async () => {
        const dataToSave = {
            idInspItem: props.isAssociated ? dropdownOptionsState.inspItemOptions?.filter(x => x.text === data?.itemCode)[0].value.toString() ?? '' : newInspectionItem,
            idCategory: selectedCategory,
            idDisciplina: selectedDiscipline,
            idPunchListType: selectedPunchlistType,
            idActionParty: selectedActionParty,
            description: newDescription,
            attachment: newAttachments,
            idUniqueITR: props.isAssociated ? data?.id.toString() ?? '' : '',
        }
        await AddUpdatePunchlistModel(dataToSave, "Insert").then((res) => {
            if (res?.info === 'Operation success!') {
                if (props.isAssociated) {
                    setIsViewCreatedOpen(true)
                } else {
                    props.action.backAction(true);
                }
            }
        })
    }

    const selectValueFromSelect = (property: string, obj: any) => {
        switch (property) {
            case 'Category':
                setSelectedCategory(obj.key)
                break;
            case 'Discipline':
                setSelectedDiscipline(obj.key)
                break;
            case 'ActionParty':
                setSelectedActionParty(obj.key)
                break;
            case 'InspItem':
                setNewInspectionItem(obj.key)
                break;
            case 'PunchListType':
                setSelectedPunchlistType(obj.key)
                break;
        }
    }


    const darkTheme = useContext(DarkTheme)

    return (
        <>
            {
                isViewCreatedOpen ?
                    <ModalConfirm data={plCreatedData} />
                    :
                    <div className="w-100">
                        <ModalImgPreview data={previewData} />
                        <div className="d-flex align-items-center justify-content-start col-12 px-1 mb-4">
                            <ActionButtonIcon buttonClassName="icon-sm" imageClassName={darkTheme ? "invert" : ""} action={() => props.action.backAction(changed)} icon={left} />
                            <h2 className="mb-0">PL {props.isAssociated ? "item" : "Register"}</h2>
                        </div>
                        <div className="col-12 d-flex flex-wrap">
                            {
                                props.isAssociated ?
                                    <div className="col-12 pt-2">
                                        <div className="punchlist-item row mx-0 mb-3">
                                            <div className="col-12 row mx-0 py-0 py-lg-2">
                                                <PropertyItem label={"Inspection item"} property={"Inspection item"} index={0} value={data?.itemCode} />
                                                <PropertyItem label={"System"} property={"System"} index={0} value={data?.systemCode} />
                                                <PropertyItem label={"Sub System"} property={"Sub System"} index={0} value={data?.subSystemCode} />
                                            </div>
                                            <div className="col-8 row mx-0 py-0 py-lg-2">
                                                <PropertyItem label={"ITR"} property={"ITR"} index={0} value={data?.itrCode} />
                                                <PropertyItem label={"Unique ITR Id "} property={"Unique ITR Id "} index={0} value={data?.punchList[0]?.idUniqueITR} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <SelectComponent
                                        key={`InspItem`}
                                        action={{ onChange: selectValueFromSelect }}
                                        data={{ property: "InspItem", selected: newInspectionItem, options: dropdownOptionsState.inspItemOptions ?? [] }}
                                        settings={{ label: 'INSPECTION ITEM*' }}>
                                    </SelectComponent>
                            }
                            <SelectComponent
                                key={`Category`}
                                action={{ onChange: selectValueFromSelect }}
                                data={{ property: "Category", selected: selectedCategory, options: dropdownOptionsState.categoryOptions ?? [] }}
                                settings={{ label: 'Category' }}>
                            </SelectComponent>
                            <SelectComponent
                                key={`Discipline`}
                                action={{ onChange: selectValueFromSelect }}
                                data={{ property: "Discipline", selected: selectedDiscipline, options: dropdownOptionsState.disciplineOptions ?? [] }}
                                settings={{ label: 'Discipline' }}>
                            </SelectComponent>
                            <SelectComponent
                                key={`PunchListType`}
                                action={{ onChange: selectValueFromSelect }}
                                data={{ property: "PunchListType", selected: selectedPunchlistType, options: dropdownOptionsState.plTypeOptions ?? [] }}
                                settings={{ label: 'PunchList Type' }}>
                            </SelectComponent>
                            <SelectComponent
                                key={`ActionParty`}
                                action={{ onChange: selectValueFromSelect }}
                                data={{ property: "ActionParty", selected: selectedActionParty, options: dropdownOptionsState.actionPartyOptions ?? [] }}
                                settings={{ label: 'Action Party' }}>
                            </SelectComponent>
                        </div>
                        <div className="col-12">
                            <div className="d-flex col-12 px-0 justify-content-between align-items-center pb-2">
                                <h4 className="">Description*</h4>
                                <div className="d-flex align-items-center">
                                    <label className={`btn btn-icon buttonAction trasparent-bg mx-1 icon-lg mb-0 ${!navigator.onLine ? "disabled" : ""}`} htmlFor={`uploadPlAttachment`}>
                                        <img alt="icon" className={darkTheme ? "brightness" : ""} src={paperclip}></img>
                                        <input accept="image/*" className='d-none' multiple={true} id={`uploadPlAttachment`} type="file" onClick={() => setNewAttachments([])} onChange={handleChangeAttachments} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 row mx-0 py-0 py-lg-2">
                                <textarea onChange={(e) => setNewDescription(e.target.value)} style={{ height: '120px' }} className={`form-control inputForm ${darkTheme ? "dark" : ""}`} />
                            </div>
                            {
                                newAttachments.length > 0 &&
                                <div className="create-pl-item-container row mb-3">
                                    <div className="d-flex create-pl-item py-lg-2 col-12 justify-content-between align-items-center">
                                        {
                                            [...newAttachments].map((item, index) => {
                                                return (
                                                    <Attachments key={index} isNew={true} index={index} title={item.name} handlePreview={handleOpenPreview} handleDownload={() => console.log()} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            <div className="d-flex align-items-center justify-content-end col-12 px-1 mt-4" >
                                <Button disabled={submitDisabled} className='col-6 col-md-3 col-lg-2 bold' style={{ backgroundColor: '#f3cc22', borderStyle: 'none', height: '35px' }} variant="light" onClick={() => handleSubmit()}>Create</Button>
                            </div>
                        </div>
                    </div>
            }
        </>

    );
}
export default CreatePunchlist


