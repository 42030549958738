import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import NavFooterMobile from './Nav/NavFooterMobile';
import NavHeaderMobile from './Nav/NavHeaderMobile';
import { onResize } from '../Hook/Layout/LayoutHook';
import { useHistory } from 'react-router';
import { RootState } from '../Redux/Store/rootStore';
import { useSelector } from 'react-redux';
import NavHeader from './Nav/NavHeader';

interface Props {
  darkTheme: boolean
}

const Layout: React.FC<Props> = (props) => {

  window.addEventListener("resize", onResize)
  const history = useHistory()

  useEffect(() => {
    onResize();
    history.replace({ pathname: '/' })
  }, []);

  const LayoutData = (state: RootState) => state.layoutReducer;
  const layout = useSelector(LayoutData);

  return (
    <div className={props.darkTheme ? "appContainer dark" : "appContainer"}>
      {layout.isMobile ?
        <NavHeaderMobile />
        : <NavHeader />}
      <Container>
        {props.children}
      </Container>
      {layout.isMobile ?
        <NavFooterMobile />
        : null}
    </div>
  );
}

export default Layout
