import React, { SetStateAction, useContext, useEffect, useState } from "react";
import '../../Css/Filters.css'
import '../../Css/Index.css'
import { ItrModelQuery } from "../../Model/ItrList";
import { QueryObject, RenderDetail } from '../../Model/CommonModel';
import { GetFilterColumnItrModel } from "../../Redux/Action/ItrList/ItrListGridAction";
import { useFilterCustom } from '../../Hook/Grid/useFilterCustom';
import { SelectFilterType } from "../../Hook/Grid/SelectFilterAutogen";
import { arrayIsNullOrEmpty } from '../../Hook/Common/Utility';
import { DarkTheme } from "../../App";
import filter from '../../img/filters.png'
import { PunchlistModelQuery } from "Model/PunchlistList";
import { GetFilterColumnPunchlistModel } from "Redux/Action/PunchlistList/PunchlistListGridAction";

interface Props {
    action: {
        applyFilters(query: SetStateAction<QueryObject>): any
    }
    containerClass?: string
    renderGrid: RenderDetail[] | undefined;
    query: QueryObject;
    initialStateQuery: QueryObject;
    propertyToNotOverride?: string;
    isPLFilters?: boolean;
}

const FiltersMenu: React.FC<Props> = (props) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const darkTheme = useContext(DarkTheme)

    const { getAllFilters, submit, reset, filtersResource, filtersSelected, selectFilterAction, booleanRadioFilterAction,multiRadioFilterAction } = useFilterCustom<ItrModelQuery | PunchlistModelQuery>(props.action.applyFilters, props.initialStateQuery, props.isPLFilters ? GetFilterColumnPunchlistModel : GetFilterColumnItrModel, props.query, props.renderGrid ?? []);

    useEffect(() => {
        if (props.renderGrid !== undefined && (filtersResource === undefined || arrayIsNullOrEmpty(filtersResource))) {
            getAllFilters()
        }
    }, [props.renderGrid])

    const openCloseMenu = (refresh?: boolean) => {
        setIsOpen(!isOpen)
        if (refresh === true) {
            getAllFilters()
        }
    }

    const additionalFilters = props.children

    return (
        <div className={`row mx-0 ${props.containerClass ?? ""}`}>
            <button type="button" className="btn btn-icon icon-md trasparent-bg" onClick={() => openCloseMenu()}>
                <img alt="filter" className={darkTheme ? "brightness" : ""} src={filter}></img>
            </button>
            <div className={`col-12 py-3 filterMenu-container ${!isOpen ? "d-none" : ""} ${darkTheme ? "dark" : ""}`}>
                <div className="col-12 d-flex align-items-center justify-content-between">
                    <h3 className="text-white mb-0">Filters</h3>
                    <button className="btn btn-icon trasparent-bg" type="button" onClick={() => openCloseMenu()}>
                        <img alt="close" src={require('../../svg/close.svg').default}></img>
                    </button>
                </div>
                <div className="col-12 px-0 row mx-0 d-flex align-items-center py-3">
                    {props.renderGrid?.filter(x => x.showFilter === true && x.type !== 3)?.map((render, i) =>
                        SelectFilterType(render.type, selectFilterAction, booleanRadioFilterAction,multiRadioFilterAction, render.propertyName, render.propertyText, filtersSelected, filtersResource ?? [])
                    )}
                    {additionalFilters}
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center pt-3 pb-4">
                    <button className="btn btn-xl trasparent-bg mr-3 text-white white-border" type="button" onClick={() => reset(props.initialStateQuery, props.propertyToNotOverride)}>
                        Reset
                    </button>
                    <button className="btn btn-xl primary-bg" type="button" onClick={() => submit(openCloseMenu)}>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}
export default FiltersMenu