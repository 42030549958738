import { CustomGridRender, DateFilter, FilterValueDto, QueryObject, ResultDto } from './CommonModel';
import { PunchlistModel } from './PunchlistList';

export interface ItrModel {
    itrCode: string
    itemCode: string
    discipline: string
    itrType: string
    status: number
    statusTxt: string
    date: Date
    id: number
    rejected: string
    projectCode: string
    systemCode: string
    subSystemCode: string,
    fileName: string,
    on: string,
    inspectionItem:string,
    punchList: PunchlistModel[],
}

export interface ItrModelQuery extends QueryObject {
    fullText?: string
    itrCode?: string[]
    projectCode?: string[]
    itemCode?: string[]
    discipline?: string[]
    itrType?: string[]
    status?: number[]
    date?: DateFilter
    offline?: boolean
}

export interface ApprovedModel {
    id: number
    file?: Blob
}

export interface ItrModelGrid {
    gridResult: ItrListQueryResultDto | null
    filter: FilterValueDto[] | null
    counter?:number
}

export interface ItrModelDetail {
    dtoEdit: ItrModel | null,
    resultDto: ResultDto | null,
}
export interface ItrModelDownloaded {
    dtoDownload: number[],
}

export interface ItrListQueryResultDto {
    /**
     *
     * @type {number}
     * @memberof ItrListQueryResultDto
     */
    totalItems?: number;
    /**
     *
     * @type {Array<ItrList>}
     * @memberof ItrListQueryResultDto
     */
    items?: Array<ItrModel>;
    /**
     *
     * @type {CustomGridRender}
     * @memberof ItrListQueryResultDto
     */
    gridRender?: CustomGridRender;
}

export const GET_ITR_GRID = "GET_ITR_GRID"
export const ADD_ITR_GRID = "ADD_ITR_GRID"
export const ADD_ITR_COUNTER = "ADD_ITR_COUNTER"
export const GET_ITR_FILTERS = "GET_ITR_FILTERS"
export const GET_ITR_COUNTER = "GET_ITR_COUNTER"
export const GET_ITR_DETAIL = "GET_ITR_DETAIL"
export const EDIT_ITR = "EDIT_ITR"





//#region offline definition

export const ITR_LOCAL = "ITR_LOCAL"
export const ITR_FILTERS_LOCAL = "ITR_FILTERS_LOCAL"

export const ITR_GRIDRENDER = "ITR_GRIDRENDER"

//#endregion
