import { EventType, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { rootStore } from "../Redux/Store/rootStore";
import * as Config from './config';

const configEnv = Config.environment[window.location.host];

export const msalConfig = {
  auth: {
    authority: Config.authority,
    clientId: configEnv.clientId,
    redirectUri: configEnv.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false, 
  },
  system: {
    loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
                return;
            }
            switch (level) {
                case 0:
                    console.error(message);
                    return;
                case 1:
                    console.info(message);
                    return;
                case 2:
                    console.debug(message);
                    return;
                case 3:
                    console.warn(message);
                    return;
            }
        }  
      }
  }
}

export const msalInstance = new PublicClientApplication(msalConfig);

export async function getTokenAccessAsync() {
  const accounts = msalInstance.getAllAccounts();
  let token = "";

  if (accounts.length > 0) {
    const request = {
        scopes:  configEnv.scopes,
        account: msalInstance.getActiveAccount() || accounts[0]
    };
    
    try {
      const response = await msalInstance.acquireTokenSilent(request);
      token = "Bearer " + response.accessToken;
      rootStore.dispatch({ type: "ACCESS_TOKEN_ASSIGN", payload: response.accessToken });
      rootStore.dispatch({ type: "AUTH_INSTANCE", payload: msalInstance as any });  
    } 
    catch(error) {        
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect(request);
        }
    }
  }
  return token;
}

// msalInstance.addEventCallback((message) => {
//   if (message.eventType === EventType.LOGIN_SUCCESS) {
//       console.log('AAD_LOGIN_SUCCESS');
//       console.log(message.payload);
//       rootStore.dispatch({type: "AAD_LOGIN_SUCCESS", payload: message.payload as any});
//   }
// });
