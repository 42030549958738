import React, { useContext, useRef} from 'react';
import '../../Css/ItrList.css'
import ActionButtonIcon from '../../Components/GridSystem/ActionButtonIcon';
import { SelectGridPropertyItem } from '../../Hook/Grid/SelectGridPropertyItem';
import { ItrModel, ItrModelQuery, ITR_LOCAL } from '../../Model/ItrList';
import { RenderDetail, DataModalConfirm, stateConfirm } from '../../Model/CommonModel';
import { setCssClass, arrayIsNullOrEmpty } from '../../Hook/Common/Utility';
import { DownloadItrItem } from '../../Redux/Action/ItrList/ItrListDownloadAction'
import { rootStore } from '../../Redux/Store/rootStore';
import { StoreItr } from '../../Redux/Action/ItrList/ItrStore';
import localforage from 'localforage';
import { useEffect } from 'react';
import work from '../../img/work.png'
import upload from '../../img/upload.png'
import { ChangeStatusItrModel, ChangeStatusItrRejected, RejectItrModel } from '../../Redux/Action/ItrList/ItrListEditAction';
import { DarkTheme } from '../../App';

interface Props {
  action: {
    openDetail(item: ItrModel): any;
    setConfirm(data: DataModalConfirm): any
    setQuery(query: ItrModelQuery, forceClassicPagination?: boolean): any
  }
  renderGrid: RenderDetail[] | undefined;
  data: ItrModel[] | undefined
  query: ItrModelQuery
  downloadedList: number[]
}

const ItrsListGrid: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme);
  const elements = props.data ?? [];
  const fileInputRefs ={}
  elements.forEach((e) => {
      fileInputRefs[e.id] = React.createRef();
  })

  useEffect(() => {
    async function getLocalForage() {
      const list = await localforage.getItem(ITR_LOCAL) as ItrModel[]
      const arr = [] as number[]
      if (!arrayIsNullOrEmpty(list)) {
        list?.map(x => {
          arr.push(x.id)
        })
        rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
      }
    }
    getLocalForage()
  }, [])

  const InvocheDownload = async (id: number) => {
    const result = await DownloadItrItem(id);
    if (result !== undefined) {
      const url = window.URL.createObjectURL(result.file);
      const a = document.createElement("a");
      a.href = url;
      a.download = result.fileName;
      a.click();
      rootStore.dispatch({ type: "PUSH_DOWNLOAD", payload: [id] });
    }
    if (props.data && result !== undefined) {
      const res = props.data?.filter(x => x.id === id);
      StoreItr(res[0], result?.fileName ?? "")
    }
  };

  const rtnFileDownloaded = (id: number) => {
    const index = props.downloadedList.findIndex(x => x === id)
    return index !== -1
  }

  const clearFileInput = (index: number) => {
    if(fileInputRefs){
       fileInputRefs[index].current.value = ""
    }
  };

  const confirmUploadFile = (id: number, files: FileList | null, status: string) => {
    if (files !== null) {
      switch (status) {
        case "New":
          props.action.setConfirm({
            title: "Do you want to upload this file?",
            buttonText: "Confirm",
            secondaryButtonText: "Cancel",
            hideCancel: true,
            message: `<b>File name:</b> ${files[0].name}`,
            isOpen: true,
            actions: {
              cancel: () => { props.action.setConfirm(stateConfirm) },
              confirm: async () => {
                await ChangeStatusItrModel(id, files[0]).then(x => {
                  props.action.setConfirm(stateConfirm)
                  props.action.setQuery(props.query, true)

                });
              },
              secondaryConfirm: async () => {props.action.setConfirm(stateConfirm)},
            },
          })
          return;

        case "Verified":
          props.action.setConfirm({
            title: "Select an action on this ITR",
            buttonText: "Approve",
            secondaryButtonText: "Reject",
            hideCancel: true,
            message: `Your file is ready to be uploaded, <br/> select an action to continue. <br/><b>File name:</b> ${files[0].name}`,
            isOpen: true,
            actions: {
              cancel: () => { props.action.setConfirm(stateConfirm) },
              confirm: async () => {
                await ChangeStatusItrModel(id, files[0]).then(x => {
                  props.action.setConfirm(stateConfirm)
                  props.action.setQuery(props.query, true)

                });
              },
              secondaryConfirm: async () => {
                await ChangeStatusItrRejected(id, files[0]).then(x => {
                  props.action.setConfirm(stateConfirm)
                  props.action.setQuery(props.query, true)

                });
              },
            },
          })
          return;
        case "Rejected":
          props.action.setConfirm({
            title: "Do you want to upload this file?",
            buttonText: "Confirm",
            message: `<b>File name:</b> ${files[0].name}`,
            isOpen: true,
            actions: {
              cancel: () => {props.action.setConfirm(stateConfirm)},
              confirm: async () => {
                await ChangeStatusItrModel(id, files[0]).then(x => {
                  props.action.setConfirm(stateConfirm)
                  props.action.setQuery(props.query, true)
                });
              },
            },
          })
          return;

        default:
          props.action.setConfirm({
            title: "Select an action on this ITR",
            buttonText: "Verify",
            secondaryButtonText: "Reject",
            hideCancel: true,
            message: `Your file is ready to be uploaded, <br/> select an action to continue. <br/><b>File name:</b> ${files[0].name}`,
            isOpen: true,
            actions: {
              cancel: () => {props.action.setConfirm(stateConfirm)},
              confirm: async () => {
                await ChangeStatusItrModel(id, files[0]).then(x => {
                  props.action.setConfirm(stateConfirm)
                  props.action.setQuery(props.query, true)
                });
              },
              secondaryConfirm: async () => {
                await ChangeStatusItrRejected(id, files[0]).then(x => {
                  props.action.setConfirm(stateConfirm)
                  props.action.setQuery(props.query, true)
                });
              },
            },
          })
          return;
      }
      
    }
  }

  return (
    <div className="w-100 pt-4 row mx-0">
      <div className="col-12 px-0">
        {/* LIST ITEM */}
        {!arrayIsNullOrEmpty(props.data) ? props.data?.map((item, i) => (
          <div className={`itr-item ${darkTheme ? "dark" : ""}`} key={`${item.id}-${i}`} id={`itemList${item.id}`}>
            <div className="col clickable row mx-0"
              onMouseEnter={() => setCssClass(`itemList${item.id}`, `itr-item ${darkTheme ? "dark hover" : "hover"}`)}
              onMouseLeave={() => setCssClass(`itemList${item.id}`, `itr-item ${darkTheme ? "dark" : ""}`)}
              onClick={() => props.action.openDetail(item)}>
              {props.renderGrid?.filter(x => x.show === true)?.map((prop, propIndex) => (
                SelectGridPropertyItem(
                  item[prop.propertyName],
                  prop.propertyName,
                  prop.propertyName === "statusTxt" || prop.propertyName === "date" ? "" : prop.propertyText,
                  prop.type,
                  i,
                  "mb-0",
                  "mb-0")
              ))}
            </div>
            <div className="px-2 d-flex justify-content-end align-content-start row mx-0">
              <ActionButtonIcon buttonClassName="icon-lg" imageClassName={!rtnFileDownloaded(item.id) && darkTheme ? "brightness" : ""} disabled={!navigator.onLine} item1={item.id} active={rtnFileDownloaded(item.id)} action={InvocheDownload} icon={work} />
              <label className={`btn btn-icon buttonAction trasparent-bg mx-1 icon-lg mb-0 ${!navigator.onLine ? "disabled" : ""}`} htmlFor={`upload${item.id.toString()}`}>
                <img alt="icon" className={darkTheme ? "brightness" : ""} src={upload}></img>
                <input ref={fileInputRefs[item.id]} className='d-none' disabled={!navigator.onLine} multiple={false} id={`upload${item.id.toString()}`} type="file" onClick={() => clearFileInput(item.id)} onChange={(e) => confirmUploadFile(item.id, e.target.files, item.statusTxt)}/>
              </label>
            </div>
          </div>
        ))
          :
          <div className="w-100 my-2">
            <h5>The search did not return any results</h5>
          </div>
        }
      </div>
    </div>
  );
}
export default ItrsListGrid
