import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from './Components/Layout';
import ItrsListContainer from './Screen/Containers/ItrsListContainer';
import * as Config from './auth/config';
import './custom.css'
import Loader from './Components/Loader';
import ErrorNotification from './Components/ErrorNotification';
import ProfileContainer from './Screen/Containers/ProfileContainer';
import ArchiveContainer from './Screen/Containers/ArchiveContainer';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { msalInstance } from './auth/msalProvider';
import PunchlistContainer from 'Screen/Containers/PunchlistContainer';

export const DarkTheme = React.createContext(false)

const App = () => {

  const [isOnline, setIsOnline] = useState<boolean>(true)
  const [darkTheme, setDarkTheme] = useState<boolean>(false)
  

  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true))
    window.addEventListener("offline", () => setIsOnline(false))

    const theme = localStorage.getItem("theme")
    const root = document.getElementById("root")

    if (theme === "dark") {
      setDarkTheme(true)
      if(root){
        root.setAttribute("class", "darkTheme")
      }
    } else {
      if(root){
        root.setAttribute("class", "")
      }
      setDarkTheme(false)
    }
  }, [])

  return (
    <DarkTheme.Provider value={darkTheme}>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect}
            authenticationRequest={{
              scopes: Config.environment[window.location.host].scopes
            }}>
          <Router>
            <Loader></Loader>
            <ErrorNotification OnModal={false} />        
            <Layout darkTheme={darkTheme}>
              <Switch>
                <Route exact path='/' >
                  <ItrsListContainer isOnline={isOnline}></ItrsListContainer>
                </Route>
                <Route exact path='/punchlist' >
                  <PunchlistContainer isOnline={isOnline}></PunchlistContainer>
                </Route>
                <Route exact path='/profile' >
                  <ProfileContainer logout={() => msalInstance.logoutRedirect()} setDarkTheme={setDarkTheme}></ProfileContainer>
                </Route>
                <Route exact path='/archive' >
                  <ArchiveContainer isOnline={isOnline}></ArchiveContainer>
                </Route>
              </Switch>
            </Layout>        
          </Router>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </DarkTheme.Provider>
  );
}

export default App
