import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { LoaderReducer } from '../Reducer/LoaderReducer';
import { errorReducer } from '../Reducer/NotificationReducer';
import { LayoutReducer } from '../Reducer/LayoutReducer';
import { ItrListGridReducer } from '../Reducer/ItrList/ItrListGridReducer';
import { ItrListDetailReducer } from '../Reducer/ItrList/ItrListDetailReducer';
import { ItrListDownloadReducer } from '../Reducer/ItrList/ItrListDownloadReducer';
import { PunchlistListGridReducer } from "Redux/Reducer/punchlistList/punchlistListGridReducer";
import { PunchlistListDetailReducer } from "Redux/Reducer/punchlistList/punchlistListDetailReducer";
import { PunchlistListDownloadReducer } from "Redux/Reducer/punchlistList/punchlistListDownloadReducer";


const logger = (store) => (next) => (action) => {
	if(action.type !== "ADD" && action.type !== "REMOVE"){
	}
	
	const result = next(action);

	if(action.type !== "ADD" && action.type !== "REMOVE"){
	}
	
	return result;
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootReducer = combineReducers({
	loaderReducer: LoaderReducer,
	errorReducer: errorReducer,
	layoutReducer: LayoutReducer,

	itrListGridReducer: ItrListGridReducer,
	itrListDetailReducer: ItrListDetailReducer,
	itrListDownloadReducer:ItrListDownloadReducer,

	punchlistListGridReducer: PunchlistListGridReducer,
	punchlistListDetailReducer: PunchlistListDetailReducer,
	punchlistListDownloadReducer:PunchlistListDownloadReducer,
});

export const rootStore = createStore(rootReducer, composeEnhancers(applyMiddleware(logger)));

export type RootState = ReturnType<typeof rootReducer>;
