import React, { useContext, useState } from 'react';
import '../../Css/Index.css'
import '../../Css/ItrList.css'
import InputSearch from '../../Components/FormAndFilters/FiltersFullTextInputSearch';
import FiltersMenuIconButton from '../../Components/FormAndFilters/FiltersMenuIconButton';
import { GetAddResultToGrid, GetPunchlistModel } from '../../Redux/Action/PunchlistList/PunchlistListGridAction';
import { useResourceTableCrud } from '../../Hook/Grid/useResourceTableCrud';
import { PunchlistModelQuery, PunchlistModel, GET_PL_DETAIL, PunchlistModelDetail } from '../../Model/PunchlistList';
import Pagination from '../../Components/PaginationComponent';
import { RootState, rootStore } from '../../Redux/Store/rootStore';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ModalConfirm from '../../Components/Various/ModalConfirm';
import { stateConfirm, DataModalConfirm } from '../../Model/CommonModel';
import { Online } from '../../Components/OnlineOffline';
import '../../img/left-chevron.png';
import '../../img/eye.png';
import { DarkTheme } from '../../App';
import PunchlistView from 'Screen/Punchlist/PunchlistView';
import AddPunchlistButton from 'Screen/Punchlist/AddPunchlistButton';
import CreatePunchlist from 'Screen/Punchlist/CreatePunchlist';
import PunchlistsListGrid from 'Screen/Punchlist/PunchlistsListGrid';

interface Props {
  isOnline: boolean
}

const PunchlistsList: React.FC<Props> = (props) => {

  const projectCode = localStorage.getItem('projectCode')
  const isEdited = localStorage.getItem("isEdited")

  const darkTheme = useContext(DarkTheme)

  const initialState: PunchlistModelQuery = {
    projectCode: projectCode !== null ? [projectCode as string] : [],
    fullText: '',
    disciplineCode: [],
    idSubsystem: [],
    statusPL: [],
    sortBy: "",
    isSortAscending: false,
    page: 1,
    pageSize: 15,
    principalId: undefined,
    orphan: false,
    deleted: false,
    lastModified: undefined,
    lastModifiedBy: [],
  }




  //UTILITY
  const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm)

  //REDUCERS
  const GridData = (state: RootState) => state.punchlistListGridReducer.gridResult;
  const GridDto = useSelector(GridData);

  const LayoutData = (state: RootState) => state.layoutReducer;
  const layout = useSelector(LayoutData);

  // const downloadedData = (state: RootState) => state.itrListDownloadReducer.dtoDownload;
  // const downloadedList = useSelector(downloadedData);

  //DETAIL
  const [isDetailOpened, setIsDetailOpened] = useState<boolean>(false)

  //CREATE
  const [isCreateOpened, setIsCreateOpened] = useState<boolean>(false)

  const openCreate = () => {
    setIsCreateOpened(true)
  }


  const closeCreate = (changed: boolean) => {
    if (changed) {
      setQuery(initialState, true)
    }
    setIsCreateOpened(false)
  }
  
  useEffect(()=>{
    if(isEdited === "true"){
      setQuery(initialState, true)
      localStorage.setItem("isEdited",`false`)
    }
  },[isEdited])

  const openDetail = async (item: PunchlistModel) => {
    rootStore.dispatch({ type: GET_PL_DETAIL, payload: { dtoEdit: item, resultDto: null } as PunchlistModelDetail });
    setIsDetailOpened(true)
  }

  const closeDetail = (changed: boolean) => {
    if (changed) {
      setQuery(initialState, true)
    }
    setIsDetailOpened(false)
  }




  //FILTERS
  const { query, setQuery, next, back } = useResourceTableCrud(initialState, GetPunchlistModel, layout.isMobile ?? true, GetAddResultToGrid, GridDto?.items);

  return (
    <div className={`col-12 px-0 mt-3 row mx-0 relative h-100 pageContainer justify-content-center ${darkTheme ? "dark" : ""}`}>
      <ModalConfirm data={confirm} />
      {
        isCreateOpened ?
          <CreatePunchlist isAssociated={false} isOnline={props.isOnline} action={{ addPlAction: () => console.log(), backAction: closeCreate }} />
          :
          isDetailOpened ?
            <PunchlistView isOnline={props.isOnline} action={{ backAction: closeDetail, setConfirm }} />
            :
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-between col-12">
                <h2 className="mb-0">Punchlist</h2>
                <FiltersMenuIconButton isPLFilters={true} containerClass={""} initialStateQuery={initialState} query={query} action={{ applyFilters: setQuery }} renderGrid={GridDto?.gridRender?.render} />
              </div>
              <div className="col-12 mt-3">
                <InputSearch propertyName={"fullText"} query={query} action={{ Submit: setQuery }} placeHolder="Find PL or Inspection items"></InputSearch>
              </div>
              <div className={`col-12 h-100 paginationScrollable`}>
                <PunchlistsListGrid action={{ openDetail, setConfirm, setQuery }} data={GridDto?.items} renderGrid={GridDto?.gridRender?.render} />
                <Online>
                  <Pagination isMobile={layout.isMobile ?? false} pagination={{ page: query.page, pageSize: query.pageSize }} totalItems={GridDto?.totalItems ?? 0} itemsLength={GridDto?.items?.length ?? 0} actions={{ next, back }} />
                </Online>
              </div>
            </div>
      }
      {
        (!isCreateOpened && !isDetailOpened) &&
        <AddPunchlistButton handleClick={() => openCreate()} />
      }
    </div>
  );
}
export default PunchlistsList

