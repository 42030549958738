
import { CustomGridRender, FilterValueDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeaderAsync } from "./Common/configuration";
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";
import { ApprovedModel } from "Model/ItrList";
import { PunchlistModelQuery, PunchlistListQueryResultDto, PunchlistModel, PL_LOCAL, PL_GRIDRENDER, PL_FILTERS_LOCAL, PL_DISCS_LOCAL, PL_CATEGORIES_LOCAL, PL_AP_LOCAL, PL_INSPITEM_LOCAL, PunchlistAddUpdateModel, PL_PUNCHLIST_TYPE_LOCAL } from "Model/PunchlistList";
import { stringIsNullOrEmpty } from "Hook/Common/Utility";

export class PunchlistListApi {

  PunchlistListGetGrid = async (tableQuery: PunchlistModelQuery): Promise<PunchlistListQueryResultDto> => {
    try {
        const options = {
            method: "POST",
            headers: await GetHeaderAsync(),
            body: JSON.stringify(tableQuery),
        };

        const response = await fetch(basePath + "PL/GetPlList", options);
        
        if (!response.ok) {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const data = await response.json() as PunchlistListQueryResultDto;
        return { status: response.status, ...data };
    } catch (error) {
        if (error instanceof Error && error.message.includes('404')) {
            console.error("Resource not found. Status: 404");
            return { status: 404, items: [], totalItems: 0, gridRender: {} as CustomGridRender };
        } else {
            console.error("Error while fetching data:", error);
            return { status: 500, items: [], totalItems: 0, gridRender: {} as CustomGridRender};
        }
    }
};

  PunchlistAddUpdate = async (data: PunchlistAddUpdateModel, operation: string,type?:string): Promise<ResultDto> => {
    const formData = new FormData();
    Object.keys(data).map((item) => {
      item !== 'attachment' && formData.append(item, data[item])
    })

    for (let index = 0; index < data.attachment.length; index++) {
      formData.append('attachment', data.attachment[index],data.attachment[index].name)      
    }

    const options = {
      method: "POST",
      headers: await GetHeaderAsync(true),
      body: formData
    };

    const url = basePath + `PL/ADDUpdatePLItem?Operation=${operation}&Type=${type}`;

    const response = await fetch(url, options);
    return await response.json() as ResultDto;
  }

  PunchlistImageDownload = async (id: number, idUniquePL: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeaderAsync(),
    };
    const url = basePath + `PL/Image?id=${id}&idUniquePL=${idUniquePL}`;
    const response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  PunchlistListGetFilters = async (columName: string, columValue?: string, queryFilter?: PunchlistModelQuery): Promise<FilterValueDto[]> => {
    const options = {
      method: "POST",
      headers: await GetHeaderAsync(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "PL/PLFilter?propertyName=" + columName;

    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }

    const response = await fetch(url, options);
    return await response.json() as FilterValueDto[];
  }

  PunchlistItemChangeStatus = async (model: ApprovedModel): Promise<ResultDto> => {
    const formData = new FormData();
    formData.append("id", model.id?.toString())

    if (model.file)
      formData.append("file", model?.file)

    const options = {
      method: "POST",
      headers: await GetHeaderAsync(true),
      body: formData
    };

    const url = basePath + "PL/ChangeState";

    const response = await fetch(url, options);
    return await response.json() as ResultDto;
  }

  PunchlistItemChangeStatusRejected = async (model: ApprovedModel): Promise<ResultDto> => {
    const formData = new FormData();
    formData.append("id", model.id?.toString())

    if (model.file)
      formData.append("file", model.file)

    const options = {
      method: "POST",
      headers: await GetHeaderAsync(true),
      body: formData
    };

    const url = basePath + "PL/ChangeStateReject";

    const response = await fetch(url, options);
    return await response.json() as ResultDto;
  }

  PunchlistItemReject = async (id: number): Promise<ResultDto> => {

    const options = {
      method: "DELETE",
      headers: await GetHeaderAsync(),
    };

    const url = basePath + "PL?id=" + id;

    const response = await fetch(url, options);
    return await response.json() as ResultDto;
  }

  PunchlistGetDiscList = async (): Promise<FilterValueDto[]> => {
    const options = {
      method: "POST",
      headers: await GetHeaderAsync(),
    };
    const url = basePath + "PL/GetDiscList";

    const response = await fetch(url, options);
    return await response.json() as FilterValueDto[];
  }

  PunchlistGetCategoryList = async (): Promise<FilterValueDto[]> => {
    const options = {
      method: "POST",
      headers: await GetHeaderAsync(),
    };
    const url = basePath + "PL/GetCategoryList";

    const response = await fetch(url, options);
    return await response.json() as FilterValueDto[];
  }

  PunchlistGetAPList = async (idProject: number): Promise<FilterValueDto[]> => {
    const options = {
      method: "POST",
      headers: await GetHeaderAsync(),
    };
    const url = basePath + `PL/GetAPList?idProject=${idProject}`;

    const response = await fetch(url, options);
    return await response.json() as FilterValueDto[];
  }

  PunchlistGetPunchListTypeList = async (): Promise<FilterValueDto[]> => {
    const options = {
      method: "POST",
      headers: await GetHeaderAsync(),
    };
    const url = basePath + `PL/GetPLTypeList`;

    const response = await fetch(url, options);
    return await response.json() as FilterValueDto[];
  }

  PunchlistGetInspItemList = async (idProject: number): Promise<FilterValueDto[]> => {
    const options = {
      method: "POST",
      headers: await GetHeaderAsync(),
    };
    const url = basePath + `PL/GetInspItemList?idProject=${idProject}`;

    const response = await fetch(url, options);
    return await response.json() as FilterValueDto[];
  }




}


export class PunchlistListApiOffline {

  // PunchlistListGetGrid = async (tableQuery: PunchlistModelQuery): Promise<PunchlistListQueryResultDto> => {

  //   let items: PunchlistModel[] | null = await localforage.getItem(PL_LOCAL);
  //   const gridRender: CustomGridRender = await GetGridRender(PL_GRIDRENDER);

  //   if (!stringIsNullOrEmpty(tableQuery.fullText)) {
  //     items = items?.filter(x => x.idUniqueITR?.toString().includes(tableQuery.fullText as string) || x.inspItemCode.includes(tableQuery.fullText as string)) ?? []
  //   }
  //   tableQuery.disciplineCode?.forEach(s => {
  //     items = items?.filter(x => x.disciplineCode === s) ?? []
  //   });
  //   tableQuery.idSubsystem?.forEach(s => {
  //     items = items?.filter(x => x.idSubsystem?.toString() === s) ?? []
  //   });
  //   tableQuery.statusPL?.forEach(s => {
  //     items = items?.filter(x => x.statusPL === s) ?? []
  //   });
  //   const res: PunchlistListQueryResultDto = { gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length }
  //   return res;
  // }

  PunchlistListGetGrid = async (tableQuery: PunchlistModelQuery): Promise<PunchlistListQueryResultDto> => {
    let items: PunchlistModel[] | null = await localforage.getItem(PL_LOCAL);
    const gridRender: CustomGridRender = await GetGridRender(PL_GRIDRENDER);

    if (items) {
        if (!stringIsNullOrEmpty(tableQuery.fullText)) {
            items = items.filter(x => 
                (x.idUniqueITR?.toString().includes(tableQuery.fullText as string)) ||
                (x.inspItemCode.includes(tableQuery.fullText as string))
            );
        }

        if (tableQuery.disciplineCode && tableQuery.disciplineCode.length > 0) {
            items = items.filter(x => tableQuery?.disciplineCode?.includes(x.disciplineCode));
        }

        if (tableQuery.idSubsystem && tableQuery?.idSubsystem?.length > 0) {
            items = items.filter(x => tableQuery?.idSubsystem?.includes(x.idSubsystem?.toString()));
        }

        if (tableQuery.statusPL && tableQuery?.statusPL.length > 0) {
            items = items.filter(x => tableQuery?.statusPL?.includes(x.statusPL));
        }
    } else {
        items = [];
    }

    const res: PunchlistListQueryResultDto = { gridRender: gridRender ?? {} as CustomGridRender, items: items, totalItems: items.length }
    return res;
}

  PunchlistListDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeaderAsync(),
    };
    const url = basePath + "PL/File?id=" + id;
    const response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  PunchlistListGetFilters = async (columName: string, columValue?: string, queryFilter?: PunchlistModelQuery): Promise<FilterValueDto[]> => {
    const items: FilterValueDto[] | null = await localforage.getItem(PL_FILTERS_LOCAL + columName);
    return items ?? [];
  }

  PunchlistGetDiscList = async (): Promise<FilterValueDto[]> => {
    const items: FilterValueDto[] | null = await localforage.getItem(PL_DISCS_LOCAL);
    return items ?? [];
  }

  PunchlistGetCategoryList = async (): Promise<FilterValueDto[]> => {
    const items: FilterValueDto[] | null = await localforage.getItem(PL_CATEGORIES_LOCAL);
    return items ?? [];
  }

  PunchlistGetAPList = async (idProject: number): Promise<FilterValueDto[]> => {
    const items: FilterValueDto[] | null = await localforage.getItem(PL_AP_LOCAL);
    return items ?? [];
  }

  PunchlistGetInspItemList = async (idProject: number): Promise<FilterValueDto[]> => {
    const items: FilterValueDto[] | null = await localforage.getItem(PL_INSPITEM_LOCAL);
    return items ?? [];
  }

  PunchlistGetPunchListTypeList = async (): Promise<FilterValueDto[]> => {
    const items: FilterValueDto[] | null = await localforage.getItem(PL_PUNCHLIST_TYPE_LOCAL);
    return items ?? [];
  }
}

