import '../../Css/ItrList.css';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ActionButtonIcon from '../../Components/GridSystem/ActionButtonIcon';
import { RootState, rootStore } from '../../Redux/Store/rootStore';
// import paperclip from '../../img/paperclip.png'
import left from '../../img/left-chevron.png'
// import image from '../../img/image.png'
import { DarkTheme } from '../../App';
import SelectComponent from 'Components/FormAndFilters/SelectComponent';
import { Button } from 'react-bootstrap';
import ModalImgPreview from './ModalImgPreview';
import { DataModalImgPreview } from 'Model/CommonModel';
import ModalConfirm from 'Components/Various/ModalConfirm';
import Attachments from './Attachments';
import { Attachment } from 'Model/PunchlistList';
import { DownloadPunchlistImageItem } from 'Redux/Action/PunchlistList/PunchlistListDownloadAction';
import { AddUpdatePunchlistModel } from 'Redux/Action/PunchlistList/PunchlistListEditAction';
import { PunchlistDropdownOptions } from 'Model/PunchlistList';
import { GetDiscListModel, GetCategoryListModel, GetPunchListTypeListModel, GetAPListModel, GetInspItemListModel } from 'Redux/Action/PunchlistList/PunchlistListGridAction';
import { GET_ITR_DETAIL, ItrModelDetail } from 'Model/ItrList';


interface Props {
    action: {
        backAction(changed: boolean): any
    }
    isOnline: boolean
    isAssociated?: boolean
}

const CreatePunchlist: React.FC<Props> = (props) => {
    const itrDetailData = (state: RootState) => state.itrListDetailReducer.dtoEdit
    const itrData = useSelector(itrDetailData);

    const projectCode = localStorage.getItem('projectCode')
    const DetailData = (state: RootState) => state.punchlistListDetailReducer.dtoEdit;
    const data = useSelector(DetailData);

    const handleClosePreview = () => {
        setPreviewData(previewDefaultData)
        return () => URL.revokeObjectURL(objectUrl)
    }

    const previewDefaultData = {
        title: '',
        img: '',
        isOpen: false,
        actions: {
            cancel: () => handleClosePreview()
        }
    }


    const plCreatedData = {
        title: "Punchlist added successfully",
        buttonText: "View",
        secondaryButtonText: "Close",
        hideCancel: true,
        message: "Punchlist item was added successfully",
        isOpen: true,
        actions: {
            cancel: () => { setIsViewCreatedOpen(false) },
            confirm: () => {
                props.action.backAction(changed);
            },
            secondaryConfirm: async () => {
                setIsViewCreatedOpen(false)
            }
        },
    }

    const [changed, setChanged] = useState<boolean>(false);
    const [attachment, setAttachment] = useState<Attachment[] | undefined>(data?.attachments);
    const [newDescription, setNewDescription] = useState(data?.plDescription);
    const [preview, setPreview] = useState('');
    const [previewData, setPreviewData] = useState<DataModalImgPreview>(previewDefaultData)
    const [isViewCreatedOpen, setIsViewCreatedOpen] = useState<boolean>(false)
    const [newAttachments, setNewAttachments] = useState<File[]>([])
    const [objectUrl, setObjUrl] = useState<string>('')
    const idPL = data?.idUniquePL
    const [dropdownOptionsState, setDropDownOptionsState] = useState<PunchlistDropdownOptions>({
        disciplineOptions: [{ value: "", text: "" }],
        categoryOptions: [{ value: "", text: "" }],
        actionPartyOptions: [{ value: "", text: "" }],
        inspItemOptions: [{ value: "", text: "" }],
        plTypeOptions: [{ value: "", text: "" }],
    })
    const [selectedPunchlistType, setSelectedPunchlistType] = useState('');
    const [selectedDiscipline, setSelectedDiscipline] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedActionParty, setSelectedActionParty] = useState('');
    const [newInspectionItem, setNewInspectionItem] = useState('');


    useEffect(() => {
        const promiseDisc = GetDiscListModel()
        const promiseCategory = GetCategoryListModel()
        const plType = GetPunchListTypeListModel()
        const promiseAP = GetAPListModel(Number(projectCode))
        const promiseInsp = GetInspItemListModel(Number(projectCode))

        Promise.all([promiseDisc, promiseCategory, promiseAP, promiseInsp, plType]).then((values) => {
            setDropDownOptionsState({
                disciplineOptions: values[0],
                categoryOptions: values[1],
                actionPartyOptions: values[2],
                inspItemOptions: values[3],
                plTypeOptions: values[4],
            } as PunchlistDropdownOptions)
        })

    }, [])


    useEffect(() => {
        setSelectedPunchlistType(dropdownOptionsState.plTypeOptions?.filter((x) => x.text === data?.plTypeCode)[0]?.value || '')
        setSelectedDiscipline(dropdownOptionsState.disciplineOptions?.filter((x) => x.code === data?.disciplineCode)[0]?.value || '')
        setSelectedCategory(dropdownOptionsState.categoryOptions?.filter((x) => x.code === data?.categoryCode)[0]?.value || '')
        setSelectedActionParty(dropdownOptionsState.actionPartyOptions?.filter((x) => x.code === data?.actionPartyCode)[0]?.value || '')
        setNewInspectionItem(dropdownOptionsState.inspItemOptions?.filter((x) => x.code === data?.inspItemCode)[0]?.value || '')
    }, [dropdownOptionsState])


    useEffect(() => {
        if (objectUrl !== '') {
            setPreview(objectUrl)
        }
    }, [objectUrl])

    useEffect(() => {
        setPreviewData({
            ...previewData,
            img: preview,
        })
    }, [preview])

    const handleOpenPreview = (index) => {
        if (newAttachments.length > 0) {
            setObjUrl(URL.createObjectURL(newAttachments[index]))
            setPreviewData({
                title: newAttachments[index]?.name as string,
                img: '',
                isOpen: true,
                actions: {
                    cancel: () => handleClosePreview()
                }
            })
        }
    }

    const InvocheDownload = async (id: number, idUniquePL: number) => {
        const result = await DownloadPunchlistImageItem(id, idUniquePL);
        if (result !== undefined) {
            const url = window.URL.createObjectURL(result.file);
            const a = document.createElement("a");
            a.href = url;
            a.download = result.fileName;
            a.click();
            rootStore.dispatch({ type: "PUSH_PL_DOWNLOAD", payload: [id] });
        }
    };


    const handleSubmit = async () => {
        const dataToSave = {
            idUniqueITR: data?.idUniqueITR?.toString() || '',
            idInspItem: newInspectionItem,
            idCategory: selectedCategory,
            idDisciplina: selectedDiscipline,
            idPunchListType: selectedPunchlistType,
            idActionParty: selectedActionParty,
            description: newDescription ?? '',
            attachment: newAttachments,
            idUniquePL: idPL,
        }

        await AddUpdatePunchlistModel(dataToSave, "Update").then(() => {
            if (!props.isAssociated) {
                // Filter punchList array based on idUniquePL
                const filteredPunchList = itrData?.punchList.filter(x => x.idUniquePL === data?.idUniquePL);

                // Assign values from dataToSave to the first element of filteredPunchList
                if (filteredPunchList && filteredPunchList.length > 0) {
                    const firstItem = filteredPunchList[0];

                    firstItem.inspItemCode = dropdownOptionsState.inspItemOptions?.filter((x) => x.value === dataToSave.idInspItem)[0]?.code || '';
                    firstItem.categoryCode = dropdownOptionsState.categoryOptions?.filter((x) => x.value === dataToSave.idCategory)[0]?.code || '';
                    firstItem.disciplineCode = dropdownOptionsState.disciplineOptions?.filter((x) => x.value === dataToSave.idDisciplina)[0]?.code || '';
                    firstItem.plTypeCode = dropdownOptionsState.plTypeOptions?.filter((x) => x.value === dataToSave.idPunchListType)[0]?.text || '';
                    firstItem.actionPartyCode = dropdownOptionsState.actionPartyOptions?.filter((x) => x.value === dataToSave.idActionParty)[0]?.code || '';
                    firstItem.plDescription = dataToSave.description;
                }
                rootStore.dispatch({ type: GET_ITR_DETAIL, payload: { dtoEdit: itrData, resultDto: null } as ItrModelDetail });
            }

            localStorage.setItem("isEdited", `true`);
            props.action.backAction(changed);
        });
    };



    const selectValueFromSelect = (property: string, obj: any) => {
        switch (property) {
            case 'Category':
                setSelectedCategory(obj.key)
                break;
            case 'Discipline':
                setSelectedDiscipline(obj.key)
                break;
            case 'ActionParty':
                setSelectedActionParty(obj.key)
                break;
            case 'InspItem':
                setNewInspectionItem(obj.key)
                break;
            case 'PunchListType':
                setSelectedPunchlistType(obj.key)
                break;
        }
    }

    const darkTheme = useContext(DarkTheme)

    return (
        <>
            {
                isViewCreatedOpen ?
                    <ModalConfirm data={plCreatedData} />
                    :
                    <div className="w-100">
                        <ModalImgPreview data={previewData} />
                        <div className="d-flex align-items-center justify-content-start col-12 px-1 mb-4">
                            <ActionButtonIcon buttonClassName="icon-sm" imageClassName={darkTheme ? "invert" : ""} action={() => props.action.backAction(changed)} icon={left} />
                            <h2 className="mb-0">PL item</h2>
                        </div>
                        <div className="col-12 d-flex flex-wrap">

                            <SelectComponent
                                key={`InspItem`}
                                action={{ onChange: selectValueFromSelect }}
                                data={{ property: "InspItem", selected: newInspectionItem, options: dropdownOptionsState.inspItemOptions ?? [] }}
                                settings={{ label: 'INSPECTION ITEM*', isDisabled: true }}>
                            </SelectComponent>

                            <SelectComponent
                                key={`Category`}
                                action={{ onChange: selectValueFromSelect }}
                                data={{ property: "Category", selected: selectedCategory, options: dropdownOptionsState.categoryOptions ?? [] }}
                                settings={{ label: 'Category' }}>
                            </SelectComponent>
                            <SelectComponent
                                key={`Discipline`}
                                action={{ onChange: selectValueFromSelect }}
                                data={{ property: "Discipline", selected: selectedDiscipline, options: dropdownOptionsState.disciplineOptions ?? [] }}
                                settings={{ label: 'Discipline' }}>
                            </SelectComponent>
                            <SelectComponent
                                key={`PunchListType`}
                                action={{ onChange: selectValueFromSelect }}
                                data={{ property: "PunchListType", selected: selectedPunchlistType, options: dropdownOptionsState.plTypeOptions ?? [] }}
                                settings={{ label: 'PunchList Type' }}>
                            </SelectComponent>
                            <SelectComponent
                                key={`ActionParty`}
                                action={{ onChange: selectValueFromSelect }}
                                data={{ property: "ActionParty", selected: selectedActionParty, options: dropdownOptionsState.actionPartyOptions ?? [] }}
                                settings={{ label: 'ActionParty' }}>
                            </SelectComponent>
                        </div>
                        <div className="col-12">
                            <div className="d-flex col-12 px-0 justify-content-between align-items-center pb-2">
                                <h4 className="">Description</h4>
                                {/* <div className="d-flex align-items-center">
                                    <label className={`btn btn-icon buttonAction trasparent-bg mx-1 icon-lg mb-0 ${!navigator.onLine ? "disabled" : ""}`} htmlFor={`uploadPlAttachment`}>
                                        <img alt="icon" className={darkTheme ? "brightness" : ""} src={paperclip}></img>
                                        <input accept="image/*" className='d-none' multiple={true} id={`uploadPlAttachment`} type="file" onClick={() => setNewAttachments([])} onChange={handleChangeAttachments} />
                                    </label>
                                </div> */}
                            </div>
                            <div className="col-12 row mx-0 py-0 py-lg-2">
                                <textarea value={newDescription} onChange={(e) => setNewDescription(e.target.value)} style={{ height: '120px' }} className={`form-control inputForm ${darkTheme ? "dark" : ""}`} />
                            </div>
                            {
                                ((!!attachment && attachment?.length > 0 && attachment[0] !== null) || newAttachments.length > 0) &&
                                <div className="create-pl-item-container row mb-3">
                                    <div className="d-flex create-pl-item py-lg-2 col-12 justify-content-between align-items-center">
                                        {
                                            attachment &&
                                            attachment.map((item, index) => {
                                                return (
                                                    <Attachments idPL={data?.idUniquePL} id={item?.idAttachment} key={index} isNew={false} index={index} title={item?.fileName ?? 'image'} handlePreview={handleOpenPreview} handleDownload={InvocheDownload} />
                                                )
                                            })
                                        }
                                        {
                                            newAttachments &&
                                            [...newAttachments].map((item, index) => {
                                                return (
                                                    <Attachments key={index} isNew={true} index={index} title={item.name} handlePreview={handleOpenPreview} handleDownload={() => console.log()} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            <div className="d-flex align-items-center justify-content-end col-12 px-1 mt-4" >
                                <Button className='col-6 col-md-3 col-lg-2 bold' style={{ backgroundColor: '#f3cc22', borderStyle: 'none', height: '35px' }} variant="light" onClick={() => handleSubmit()}>SAVE</Button>
                            </div>
                        </div>
                    </div>
            }
        </>

    );
}
export default CreatePunchlist


