import React from "react";
import { Modal } from "react-bootstrap";
import "../../Css/Index.css";
import { DataModalConfirm } from "../../Model/CommonModel";
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import close from '../../img/close.png'

export interface Props {
	data: DataModalConfirm;
}

const ModalConfirm: React.FC<Props> = (props) => {

	const data = props.data;

	return (
		<Modal show={data.isOpen} onHide={data.actions.cancel} backdrop="static" backdropClassName="backdropConfirm" dialogClassName="dialogConfirm" className="modalConfirm" keyboard={false} size="lg" centered>
			<Modal.Body>
				<div className="col-12 px-0 text-center d-flex flex-column">
					<div className="w-100 text-left">
						<button type="button" className="btn btn-icon trasparent-bg icon-sm" onClick={() => data.actions.cancel()}>
							<img alt="close" src={close}></img>
						</button>
					</div>
					<h3 className="bold">{data.title}</h3>
				</div>

				<div className="col-12 px-0 text-center py-3">
					<span style={{ fontSize: "1.2em" }} dangerouslySetInnerHTML={{ __html: stringIsNullOrEmpty(data.message) ? "" : data.message }}></span>
				</div>

				<div className="col-12 px-0 justify-content-end pt-5 d-flex footerModal">
					{!data.onlyOneButton && data.hideCancel !== true ? (
						<button className="btn btn-lg bold trasparent-bg mr-3 dark-border" onClick={() => data.actions.cancel()} type="button">
							{stringIsNullOrEmpty(props.data.cancelText) ? "Cancel" : props.data.cancelText}
						</button>
					) : null}
					{!stringIsNullOrEmpty(data.secondaryButtonText) ? (
						<button className="btn btn-lg bold trasparent-bg mr-3 dark-border" onClick={() => data.actions.secondaryConfirm && data.actions.secondaryConfirm()} type="button">
							{data.secondaryButtonText}
						</button>
					) : null}
					<button className="btn btn-lg bold primary-bg" onClick={() => data.actions.confirm()} type="button">
						{stringIsNullOrEmpty(data.buttonText) ? "Confirm" : data.buttonText}
					</button>


				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalConfirm;
