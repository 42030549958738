import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { FileResult, ReturnFile } from '../../../Model/CommonModel';
import { ItrListApi } from "../../../Business/ItrListBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from "../NotificationAction";
import { rootStore } from "../../Store/rootStore";
import { NotifyType } from "../../Reducer/NotificationReducer";


export async function DownloadItrItem(id: number) {

    setLoader("ADD", "DownloadItrItem");
    const api = new ItrListApi();

    const res = await ApiCallWithErrorHandling<Promise<ReturnFile>>(() =>
       api.ItrListDownload(id)
    );
    if(res?.FileName === null){
      rootStore.dispatch(setNotification({ message: "File not found", notifyType: NotifyType.warning  }));
      setLoader("REMOVE", "DownloadItrItem");
    }else{
      const fileNameBase = res?.FileName?.split(";")[1] ?? "";
      const index = fileNameBase?.indexOf('"') + 1;
      const lastIndex = fileNameBase?.indexOf('"', index);
      const fileName = fileNameBase?.substring(index, lastIndex);
      const result = res?.File.then((x) => {
          setLoader("REMOVE", "DownloadItrItem");
          return { file: x, fileName: fileName } as FileResult;
      });
      setLoader("REMOVE", "DownloadItrItem");
      return result;
    }
}
