
import { rootStore } from "../../Redux/Store/rootStore";
import { NotifyType } from "../../Redux/Reducer/NotificationReducer";
import { setNotification } from "../../Redux/Action/NotificationAction";

/**
 *
 * @export
 * @class BaseAPI
 */

export const BASE_PATH = window.location.origin.replace(/\/+$/, "");

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name!: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}



export async function ApiCallWithErrorHandling<TDto>(arg: () => TDto) {

  // const history = useHistory()
  try {
      // var root = rootStore.getState();
      // var instance = rootStore.getState().autenticazione.instance
      // if (instance != null) {
      //     await acquireAccessToken(instance);
      // }
      const result = await arg();
      return result;
  } catch (error) {
      if ((error as any).status === 401) {
          window.location.replace(BASE_PATH + "/error")
          //  var test =  await AquiredTokenSilent(instance);
          //     if (test != null) {
          //         setTimeout(() => {
          //             arg();
          //         }, 1000);
      }

      if ((error as any).status === 400) {
          rootStore.dispatch(setNotification({ message: "Bad Request", notifyType: NotifyType.error }));
      }
  }
}




