import '../../Css/NavMenu.css';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

interface Props {
    label: string
    path: string
    icon: string
    disabled: boolean
}

const ButtonLinkIconTextHoriz: React.FC<Props> = (props) => {

    const location = useLocation();

    return (
        <Link className={`navIcon-container horiz ${location.pathname === props.path ? "active" : ""} ${props.disabled ? "disabled" : ""}`} to={{ pathname: props.path }}>
            <div className="d-flex flex-row justify-content-center align-items-center px-3 py-2">
                <img alt={props.label} src={props.icon} height="24"></img>
                <label className="mb-0 ml-2 text-nowrap">{props.label}</label>
            </div>
        </Link>
    );
}

export default ButtonLinkIconTextHoriz
