import { ADD_ITR_GRID, GET_ITR_FILTERS, GET_ITR_GRID, ItrModelGrid } from '../../../Model/ItrList';

const initState: ItrModelGrid = {
    gridResult: null,
    filter: null,
    counter:0
}

export const ItrListGridReducer = (state = initState, action: { type: string, payload: ItrModelGrid }) => {
    switch (action.type) {
        case GET_ITR_GRID:
        case ADD_ITR_GRID:
            {
                return { ...state, gridResult: action.payload.gridResult }
            }
        case GET_ITR_FILTERS:
            return { ...state, filter: action.payload.filter }
        default:
            return state;
    }
}