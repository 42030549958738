import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { ItrModel, ITR_LOCAL, ITR_FILTERS_LOCAL } from "../../../Model/ItrList"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreItr = async (itr: ItrModel, fileName: string) => {

  const itrList: ItrModel[] = await localforage.getItem(ITR_LOCAL) ?? [];
  itr.fileName = fileName;
  itr.on = formatDateWithTime(new Date())?.toString() ?? "";
  itrList.push(itr);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(itrList.map(item =>
    [item[key], item])).values()];
  localforage.setItem(ITR_LOCAL, arrayUniqueByKey);

}

export const StoreItrFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    const filterList: FilterValueDto[] = await localforage.getItem(ITR_FILTERS_LOCAL + column) ?? [];
    const union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(ITR_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


