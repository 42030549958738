import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../Redux/Store/rootStore';
import '../Css/Index.css'

const HIDE_ERROR = "HIDE_ERROR";
interface Props {
    OnModal: boolean,
    ShowFixed?: boolean,
}


const ErrorNotification: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.errorReducer);
    const [errorType, setErrorType] = useState("")
    const isOpen = state.isOpen;
    const error = state.error;
    const type = state.notifyType;

    const LayoutData = (state: RootState) => state.layoutReducer;
    const layout = useSelector(LayoutData);

    function handleClose() {
        dispatch({ type: HIDE_ERROR });
    }

    useEffect(() => {
        if (isOpen && !props.ShowFixed) {
            setTimeout(handleClose, 4000)
        }
    }, [isOpen === true])

    useEffect(() => {
        switch (type) {
            case 0:
                return setErrorType("noNotify");
            case 1:
                return setErrorType("success");
            case 2:
                return setErrorType("error");
            case 3:
                return setErrorType("warning");
            default:
                return
        }
    }, [state]);

    const rtnBreakPoint = () => {
        switch (layout.breakpoint) {
            case "xs":
            case "sm":
                return true
            default:
                return false
        }
    }

    return (
        <>
            {isOpen && (
                <div className={`d-flex ${rtnBreakPoint() ? "col px-4" : "px-3"} justify-content-end`} style={{
                    position: "fixed",
                    right: "0",
                    top: "15px",
                    minHeight: "100px",
                    zIndex: 10000
                }}>
                    <div className={`notification w-100 d-flex flex-row align-content-start ${errorType}`}>
                        <div className="col d-flex align-items-center">
                            <span className="regular">{error}</span>
                        </div>
                        <div>
                            <button className="btn btn-icon trasparent-bg d-flex align-items-center justify-content-center mt-2" onClick={() => handleClose()}>
                                <img className="" src={require("../svg/close.svg").default} alt="close" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ErrorNotification;