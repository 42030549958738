import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_ITR_GRID, ItrModelGrid, ItrModelQuery, ItrModel, ITR_GRIDRENDER, ADD_ITR_GRID } from '../../../Model/ItrList';
import { FilterValueDto } from '../../../Model/CommonModel';
import { ItrListApi, ItrListApiOffline } from "../../../Business/ItrListBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreItrFilters } from './ItrStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';
import { setNotification } from '../NotificationAction';
import { NotifyType } from 'Redux/Reducer/NotificationReducer';

export const GetItrModel = async (tableQuery: ItrModelQuery) => {
    setLoader("ADD", "GetItrModel");
    const api = navigator.onLine && tableQuery.offline === false  ? new ItrListApi():new ItrListApiOffline();
    const data = await ApiCallWithErrorHandling(() => api.ItrListGetGrid(tableQuery));
    data?.gridRender && StoreGridRender(data.gridRender ,ITR_GRIDRENDER);
    rootStore.dispatch({ type: GET_ITR_GRID, payload: { gridResult: data, filter: null } as ItrModelGrid });
}


export const GetAddResultToGrid = async (tableQuery: ItrModelQuery, dataToConcat: ItrModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");

    const api = new ItrListApi();
    const data = await ApiCallWithErrorHandling(() => api.ItrListGetGrid(tableQuery));

    if (data && !arrayIsNullOrEmpty(data.items) && data.items !== undefined) {
        const arr = dataToConcat?.concat(data.items)
        // data.totalItems = data.totalItems;
        data.items = arr;
    }

    rootStore.dispatch({ type: ADD_ITR_GRID, payload: { gridResult: data, filter: null } as ItrModelGrid });
    return data?.items
}


export async function GetFilterColumnItrModel(columName: string, columValue: string, queryFilter?: ItrModelQuery) {
    let result: FilterValueDto[] | undefined;
    const api = navigator.onLine ? new ItrListApi(): new ItrListApiOffline();
    // setLoader("ADD", "GetFilterColumnItrModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.ItrListGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.ItrListGetFilters(columName, columValue));
    }

    if (!result) {
        rootStore.dispatch(setNotification({ message: "Error retrieving Project list", notifyType: NotifyType.error }));
    }

    result && StoreItrFilters(result,columName);
    return result
}


