import React from "react";
import '../../Css/Filters.css'
import '../../Css/Index.css'
import AsyncSelect from 'react-select/async';
import { FilterValueDto } from "../../Model/CommonModel";
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';

interface Props {
    action: {
        onChange(prop: string, e: any): any,
    }
    data: {
        property: string
        options: FilterValueDto[] | undefined
        selected: (number | string) | undefined
    }
    settings: {
        label?: string
        isDisabled?: boolean
        placeHolder?: string
        optionClassName?: string
    }
}

const SelectComponent: React.FC<Props> = (props) => {

    const options = props.data.options;
    const selected = props.data.selected;

    let mapOptions;
    if (props.data.property === "Category") {
        mapOptions = options?.filter(x => !stringIsNullOrEmpty(x.value) && !stringIsNullOrEmpty(x.text)).map(x => { return { key: x.value, value: x.code, code: x.code, dto: x.dto } })
    } else {
        mapOptions = options?.filter(x => !stringIsNullOrEmpty(x.value) && !stringIsNullOrEmpty(x.text)).map(x => { return { key: x.value, value: x.text, code: x.code, dto: x.dto } })
    }

    const filterOptions = (inputValue: string) => {
        return mapOptions?.filter((i) =>
            i.value.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadOptions = (
        inputValue: string,
        callback: (mapOptions) => void
    ) => {
        setTimeout(() => {
            callback(filterOptions(inputValue)?.slice(0, 25));
        }, 1000);
    };


    return (
        <div className="form-group col-12 col-md-6">
            <label className="labelForm w-100">
                <span className="text-uppercase bold">{props.settings.label}</span>
                <div className="d-flex">
                    <div className="w-100">
                        <AsyncSelect
                            loadOptions={loadOptions}
                            defaultOptions={mapOptions?.slice(0, 25)}
                            // options={options?.filter(x => !stringIsNullOrEmpty(x.value) && !stringIsNullOrEmpty(x.text)).map(x => { return { key: x.value, value: x.text,code:x.code, dto: x.dto } })}
                            value={options?.map(x => {
                                return props.data.property === "Category" ? 
                                { key: x.value, value: x.code, code: x.code, dto: x.dto }
                                : { key: x.value, value: x.text, code: x.code, dto: x.dto }
                            }).filter(x => x.key === selected)}
                            onChange={(e) => props.action.onChange(props.data.property, e)}
                            isDisabled={props.settings.isDisabled}
                            getOptionLabel={(option) => option.value as string}
                            getOptionValue={(option) => option.key?.toString() ?? ""}
                            placeholder={props.settings.placeHolder}
                            formatOptionLabel={function (data) {
                                return <span className={`optionText ${props.settings.optionClassName}`} dangerouslySetInnerHTML={{ __html: data.value as string}} />;
                            }}
                        />
                    </div>
                </div>
            </label>
        </div>
    )
}
export default SelectComponent