import React, { useContext } from "react";
import { DarkTheme } from "../../App";
import { Offline, Online } from "../OnlineOffline";


const NavHeaderMobile: React.FC = (props) => {

  const darkTheme = useContext(DarkTheme)

  return (
    <div className={`col-12 px-0 row mx-0 headerMobileContainer ${darkTheme ? "dark" : ""} d-flex align-items-center`}>
      <div className="col-6 d-flex align-items-center pl-4 pl-lg-5">
        <img alt="logo" title="ENI" height="40" src={require('../../svg/Logo.svg').default}></img>
        <h5 className="mr-2 mb-0 mt-2 ml-2" style={{ fontWeight: 'bold' }}>EMIMS</h5>
        <h5 className="mb-0 mt-2" style={{ fontWeight: 'normal' }}>CCMS</h5>
      </div>

      <div className="col-6 trapezoidHeader">
      </div>

      <div className="col-6 d-flex align-items-center justify-content-end pr-4 pr-lg-5">
        <Online>
          <div className="logStatusIcon online mr-2"></div>
          <label className="logStatusText mb-0">Online</label>
        </Online>
        <Offline>
          <div className="logStatusIcon offline mr-2"></div>
          <label className="logStatusText mb-0">Offline</label>
        </Offline>
      </div>
    </div>
  )
}

export default NavHeaderMobile
