import React, { useContext } from 'react'
import '../Css/Index.css'
import InfiniteScroll from 'react-infinite-scroll-component';
import { QueryObject } from '../Model/CommonModel';
import { DarkTheme } from '../App';

interface Props {
    isMobile: boolean,
    pagination: QueryObject,
    itemsLength: number
    totalItems: number,
    actions: {
        next(): any | void,
        back(): any | void,
    }
}

const Pagination: React.FC<Props> = (props) => {

    const backIsVisible = (props.pagination?.page && (props.pagination?.page > 1))
    const forwardIsVisible = (props.totalItems > (props.pagination.pageSize ?? 15)) && ((props?.pagination?.page ?? 1) < Math.ceil(props.totalItems / (props.pagination.pageSize ?? 15)))
    const darkTheme = useContext(DarkTheme)

    return (
        <InfiniteScroll dataLength={props.itemsLength ?? 0} next={props.actions.next} hasMore={forwardIsVisible && props.isMobile} loader={null} scrollThreshold={1}>
            {props.isMobile === false ?
                <div className="col-12 d-flex justify-content-center align-items-center row mx-0">
                    <div className="col-1 d-flex justify-content-center p-0">
                        <button type="button" className={`pagination btn btn-link mr-3 ${backIsVisible ? "" : "notDisplay"}`} onClick={props.actions.back}>
                            <img title="back" alt="BACK" className={`pagination ${darkTheme ? "invert" : ""}`} src={require('../svg/left-chevron.svg').default} />
                        </button>
                    </div>
                    <div className="col-1 d-flex justify-content-center p-0">
                        <label className="pagination mb-0 voda-bold">{props.pagination?.page}</label>
                    </div>
                    <div className="col-1 d-flex justify-content-center p-0">
                        <button type="button" className={`pagination btn btn-link ml-3 ${forwardIsVisible ? "" : "notDisplay"}`} onClick={props.actions.next}>
                            <img title="next" alt="NEXT" className={`pagination ${darkTheme ? "invert" : ""}`} src={require('../svg/right-chevron.svg').default} />
                        </button>
                    </div>
                </div>
                : null}
        </InfiniteScroll>
    )
}

export default Pagination