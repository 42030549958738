import '../../Css/ItrList.css';
import React, { useContext, useState } from 'react';
import ActionButtonIcon from '../../Components/GridSystem/ActionButtonIcon';
import left from '../../img/left-chevron.png'
import { DataModalConfirm } from '../../Model/CommonModel';
import { DarkTheme } from '../../App';
import PunchlistDetail from './PunchlistDetail';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/Store/rootStore';
import { Button } from 'react-bootstrap';
import EditPunchlist from './EditPunchlist';


interface Props {
  action: {
    backAction(changed: boolean): any
    setConfirm(data: DataModalConfirm): any
  }
  isOnline: boolean
  buttonHidden?: boolean
}

const PunchlistView: React.FC<Props> = (props) => {
  const [changed, setChanged] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const DetailData = (state: RootState) => state.punchlistListDetailReducer.dtoEdit;
  const data = useSelector(DetailData);

  const darkTheme = useContext(DarkTheme);

  const handleEdit = () => {
    setIsEditOpen(true)
  }




  return (
    <>
      {
        isEditOpen ?
          <EditPunchlist isOnline={props.isOnline} action={{ backAction: props.action.backAction }} />
          :
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-start col-12 px-1">
              <ActionButtonIcon buttonClassName="icon-sm" imageClassName={darkTheme ? "invert" : ""} action={() => props.action.backAction(changed)} icon={left} />
              <h2 className="mb-0">Punchlist specifics</h2>
            </div>
            <PunchlistDetail isOnline={props.isOnline} action={props.action} />
            {
              data?.statusPL !== "Accepted" &&
              <div className="d-flex align-items-center justify-content-center col-12 px-1 mt-4" >
                <Button className='col-sm-12 col-md-8 col-lg-4' variant="dark" onClick={() => handleEdit()}>Edit information</Button>
              </div>
            }
            {/* {
              (data?.statusPL === "Raised") &&
              <div className="d-flex align-items-center justify-content-center col-12 px-1 mt-4" >
                <Button className='col-sm-12 col-md-8 col-lg-4' style={{ border: '1px solid black' }} variant='light' onClick={() => console.log('DELETE PL CLICK')}>Delete Punchlist</Button>
              </div>
            } */}
          </div>
      }
    </>
  );
}
export default PunchlistView



